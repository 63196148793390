import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';

import { withFirebase } from '../config';

const ForgotPassword = () => (
  <div>
    <ForgotPasswordForm />
  </div>
);

const ERROR_CODE_NO_ACCOUNT_EXISTS = 'auth/user-not-found';

const ERROR_MSG_NO_ACCOUNT_EXISTS = `
    This user does not exist
`;

const INITIAL_STATE = {
  email: '',
  loading: false
};

class ForgotPasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    M.Modal.init(this.modal);
  }

  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ loading: false });
        M.toast({ html: 'An email has been sent' });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_NO_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_NO_ACCOUNT_EXISTS;
        }
        console.log(error.code);
        M.toast({ html: error.message });
        this.setState({ loading: false });
      });
  };

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { email, loading } = this.state;
    const isInvalid = email === '' || loading === true;
    return (
      <div
        ref={modal => {
          this.modal = modal;
        }}
        id='modal-forgot-password'
        className='modal'>
        <div className='modal-content'>
          <h4 id='forgot-password-header'>
            Forgot Password
            <a
              href='#!'
              id='modal-close'
              className='modal-close btn btn-floating waves-effect btn-flat close'>
              <i className='small material-icons'>close</i>
            </a>
          </h4>
          <form onSubmit={this.onSubmit}>
            <div className='input-field'>
              <label htmlFor='email'>Email</label>
              <input onChange={this.onChange} type='text' id='email' />
            </div>
            <button
              disabled={isInvalid}
              id='forgot-password-btn'
              className='btn z-depth-0 waves-effect'>
              Send Email
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const ForgotPasswordForm = compose(withFirebase)(ForgotPasswordFormBase);

export default ForgotPassword;

export { ForgotPasswordForm };
