import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import { withRouter } from 'react-router-dom';
import Modal from '../partials/Modal';

import { withFirebase } from '../config';

const Login = () => (
  <div>
    <LoginForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  loading: false
};

class LoginFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    M.Modal.init(this.modal);
    const switchToSignupModal = document.querySelector('#switch-to-signup');
    if (switchToSignupModal) {
      switchToSignupModal.addEventListener('click', () => {
        document.querySelector('#modal-login').M_Modal.close();
        setTimeout(() => {
          document.querySelector('#modal-signup').M_Modal.open();
        }, 150);
      });
    }
    const switchToForgotPasswordModal = document.querySelector(
      '#switch-to-forgot-password'
    );
    if (switchToForgotPasswordModal) {
      switchToForgotPasswordModal.addEventListener('click', () => {
        document.querySelector('#modal-login').M_Modal.close();
        setTimeout(() => {
          document.querySelector('#modal-forgot-password').M_Modal.open();
        }, 150);
      });
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    event.preventDefault();

    this.setState({ loading: true });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        document.querySelector('#modal-login').M_Modal.close();
        M.toast({ html: 'Welcome back to Poetryphile' });
      })
      .catch(error => {
        this.setState({ loading: false });
        M.toast({ html: error.message });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { email, password, loading } = this.state;

    const isInvalid = password === '' || email === '' || loading === true;
    return (
      <Modal
        login={true}
        onSubmit={this.onSubmit}
        isInvalid={isInvalid}
        loading={loading}
        onChange={this.onChange}
      />
    );
  }
}

const LoginForm = compose(withFirebase, withRouter)(LoginFormBase);

export default Login;

export { LoginForm };
