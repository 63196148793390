import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import moment from 'moment';
import $ from 'jquery';

import Preloader from '../partials/Preloader';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../config';
import { AuthUserContext, withAuthorization } from '../session';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email: '',
      bio: '',
      savingBio: false,
      savingPassword: false,
      savingAvatar: false,
      hideExplicit: false
    };
  }
  componentDidMount() {
    document.title = 'Settings' + ' - Poetryphile';
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.onLoadUser(authUser);
      }
    });
  }
  onChangePassword = event => {
    this.setState({ [event.target.id]: event.target.value });
    document.querySelector('#save-password-btn').style.display = 'inline-block';
  };
  onChangeBio = event => {
    this.setState({ [event.target.id]: event.target.value });
    document.querySelector('#save-bio-btn').style.display = 'inline-block';
    $('#bio').val(this.state.bio);
    M.textareaAutoResize($('#bio'));
  };
  handleImageChange = e => {
    e.preventDefault();
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
    document.querySelector('#save-avatar-btn').style.display = 'inline-block';
  };
  onLoadUser = authUser => {
    const parent = this;
    this.props.firebase
      .user(authUser.uid)
      .get()
      .then(function (doc) {
        parent.setState({
          email: doc.data().email,
          bio: doc.data().bio,
          loading: false,
          hideExplicit: doc.data().hideExplicit
        });
      })
      .then(() => {
        $(function () {
          $('#upload_link').on('click', function (e) {
            e.preventDefault();
            $('#upload:hidden').trigger('click');
          });
        });
        function readURL(input) {
          if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
              $('#imagePreview').css(
                'background-image',
                'url(' + e.target.result + ')'
              );
              $('#imagePreview').hide();
              $('#imagePreview').fadeIn(650);
            };
            reader.readAsDataURL(input.files[0]);
          }
        }
        $('#upload').change(function () {
          readURL(this);
        });
      })
      .then(() => {
        $('#bio').val(this.state.bio);
        M.textareaAutoResize($('#bio'));
      });
  };
  savePassword = () => {
    this.setState({ savingPassword: true });
    this.props.firebase
      .doPasswordUpdate(this.state.password)
      .then(() => {
        M.toast({ html: 'Password saved' });
        document.querySelector('#save-password-btn').style.display = 'none';
        this.setState({ savingPassword: false });
      })
      .catch(err => {
        M.toast({ html: err.message });
        document.querySelector('#save-password-btn').style.display = 'none';
        this.setState({ savingPassword: false });
      });
  };
  saveAvatar = () => {
    const parent = this;
    if (this.state.image != null) {
      this.setState({ savingAvatar: true });
      const uploadTask = parent.props.firebase.storage
        .ref(`images/${parent.state.image.name}`)
        .put(parent.state.image);
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {},
        () => {
          parent.props.firebase.storage
            .ref('images')
            .child(parent.state.image.name)
            .getDownloadURL()
            .then(url => {
              parent.props.firebase.db
                .collection('users')
                .doc(parent.props.firebase.auth.currentUser.uid)
                .update({
                  avatar: url
                });
            })
            .then(() => {
              M.toast({
                html: 'Avatar saved'
              });
              document.querySelector('#save-avatar-btn').style.display = 'none';
              this.setState({ savingAvatar: false });
            })
            .catch(error => {
              M.toast({
                html: 'Unable to save avatar'
              });
              document.querySelector('#save-avatar-btn').style.display = 'none';
              this.setState({ savingAvatar: false });
            });
        }
      );
    }
  };
  onSwitchChange = event => {
    if (event.target.checked) {
      this.setState({
        hideExplicit: true
      });
      this.props.firebase.db
        .collection('users')
        .doc(this.props.firebase.auth.currentUser.uid)
        .update({
          hideExplicit: event.target.checked
        })
        .then(() => {
          M.toast({
            html: 'Explicit words will be hidden'
          });
        })
        .catch(err => {
          console.log(err);
          M.toast({
            html: 'Unable to change explicit words setting'
          });
        });
    } else {
      this.setState({
        hideExplicit: false
      });
      this.props.firebase.db
        .collection('users')
        .doc(this.props.firebase.auth.currentUser.uid)
        .update({
          hideExplicit: event.target.checked
        })
        .then(() => {
          M.toast({
            html: 'Explicit words will not be hidden'
          });
        })
        .catch(err => {
          console.log(err);
          M.toast({
            html: 'Unable to change explicit words setting'
          });
        });
    }
  };
  saveBio = () => {
    this.setState({ savingBio: true });
    this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .update({
        bio: this.state.bio
      })
      .then(() => {
        M.toast({ html: 'Bio saved' });
        document.querySelector('#save-bio-btn').style.display = 'none';
        this.setState({ savingBio: false });
      })
      .catch(() => {
        M.toast({ html: 'Unable to save bio' });
        document.querySelector('#save-bio-btn').style.display = 'none';
        this.setState({ savingBio: false });
      });
  };
  render() {
    const { loading, hideExplicit } = this.state;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loading == true ? (
                <Preloader />
              ) : (
                <div>
                  <ul
                    id='poems'
                    style={{ marginTop: '50px' }}
                    className='collection with-header container'>
                    <li
                      style={{ height: '90px' }}
                      className='collection-header'>
                      <p
                        style={{ marginTop: '12px' }}
                        id='my-poems'
                        className='futura'>
                        Settings
                      </p>
                    </li>
                    <li className='collection-item'>
                      <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        Change Avatar
                      </p>
                      <div className='avatar-upload'>
                        <div className='avatar-edit'>
                          <input
                            onChange={this.handleImageChange}
                            name='avatar'
                            id='upload'
                            type='file'
                          />
                          <a
                            style={{ backgroundColor: '#413043' }}
                            className='btn-floating'
                            href=''
                            id='upload_link'>
                            <i className='material-icons'>mode_edit</i>
                          </a>
                        </div>
                        <div className='avatar-preview'>
                          <div
                            style={{
                              backgroundImage: `url(${authUser.avatar})`
                            }}
                            id='imagePreview'></div>
                        </div>
                      </div>
                      {this.state.savingAvatar ? (
                        <a
                          id='save-avatar-btn'
                          className='btn z-depth-0 waves-effect disabled'>
                          Saving
                        </a>
                      ) : (
                        <a
                          onClick={this.saveAvatar}
                          id='save-avatar-btn'
                          className='btn z-depth-0 waves-effect'>
                          Save
                        </a>
                      )}
                    </li>
                    <li className='collection-item'>
                      <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        Change Password
                      </p>
                      <div className='input-field'>
                        <label htmlFor='password'>Password</label>
                        <input
                          onChange={this.onChangePassword}
                          type='password'
                          id='password'
                        />
                      </div>
                      {this.state.savingPassword ? (
                        <a
                          disabled={true}
                          id='save-password-btn'
                          className='btn z-depth-0 waves-effect'>
                          Saving
                        </a>
                      ) : (
                        <a
                          onClick={this.savePassword}
                          id='save-password-btn'
                          className='btn z-depth-0 waves-effect'>
                          Save
                        </a>
                      )}
                    </li>
                    <li className='collection-item'>
                      <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        Bio
                      </p>
                      <div className='input-field'>
                        <textarea
                          maxLength='500'
                          id='bio'
                          placeholder='Bio'
                          className='materialize-textarea'
                          onChange={this.onChangeBio}
                          value={this.state.bio}></textarea>
                        <span
                          style={{ color: '#413043' }}
                          className='helper-text'>
                          You can write a few words about yourself here for
                          other users to see
                        </span>
                        {this.state.savingBio ? (
                          <a
                            disabled={true}
                            id='save-bio-btn'
                            className='btn z-depth-0 waves-effect'>
                            Saving
                          </a>
                        ) : (
                          <a
                            id='save-bio-btn'
                            onClick={this.saveBio}
                            className='btn z-depth-0 waves-effect'>
                            Save
                          </a>
                        )}
                      </div>
                    </li>
                    <li className='collection-item'>
                      <div>
                        <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          Hide explicit words
                        </p>
                        <div
                          style={{ marginBottom: '17px' }}
                          className='switch'>
                          <label>
                            Off
                            <input
                              checked={hideExplicit}
                              onChange={this.onSwitchChange}
                              type='checkbox'></input>
                            <span className='lever'></span>
                            On
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <br />
                  <br />
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(Settings);
