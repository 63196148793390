import React, { Component } from 'react';
import M from 'materialize-css';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import Login from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import SignUp from '../auth/SignUp';
import SignOut from '../auth/SignOut';

import { withFirebase } from '../config';
import { AuthUserContext } from '../session';
import * as ROUTES from '../../constants/routes';

const Logo = () => (
  <svg
    style={{ postion: 'static' }}
    id='logo'
    width='180px'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 2000 2000'
    style={{ enableBackground: 'new 0 0 2000 2000' }}
    xml='preserve'>
    <g id='Layer_1'>
      <g>
        <path
          style={{ fill: '#da694b' }}
          d='M507,1071H291V855c59.6,0,113.6,24.2,152.7,63.3C482.8,957.4,507,1011.4,507,1071z'
        />
        <path
          style={{ fill: '#da694b' }}
          d='M363,1071c0,19.9-8.1,37.9-21.1,50.9c-13,13-31,21.1-50.9,21.1v-72H363z'
        />
        <path
          style={{ fill: '#413043' }}
          d='M543,1071L543,1071c0-139.2-112.8-252-252-252h0v-36h288v288H543z'
        />
        <g>
          <path
            style={{ fill: '#413043' }}
            d='M691.1,931.8c12.1,0,25.5,1.7,36.5,11.5c11.9,10.4,13.6,24.2,13.6,33.8c0,17.5-6.7,27.3-11.9,32.8
                            c-11.1,11.3-25.7,12.3-35.5,12.3h-19.6v48.8H642V931.8H691.1z M674.2,995.8h11.5c4.4,0,12.1-0.2,17.3-5.2
                            c2.9-2.9,5.2-7.7,5.2-13.8c0-5.8-2.1-10.6-5.2-13.6c-4.8-4.6-11.7-5.2-18-5.2h-10.9V995.8z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M848.7,1059.1c-8.3,8.3-22.5,15-40.7,15c-18.2,0-32.4-6.7-40.7-15c-9.4-9.4-14-22.8-14-34.7
                            c0-11.9,4.6-25.3,14-34.7c8.3-8.3,22.5-15,40.7-15c18.2,0,32.4,6.7,40.7,15c9.4,9.4,14,22.8,14,34.7
                            C862.7,1036.3,858.1,1049.7,848.7,1059.1z M791.1,1006.9c-4.4,4.4-6.9,10-6.9,17.5c0,8.6,3.3,14,6.9,17.5
                            c3.8,3.8,9.2,6.7,17.1,6.7c6.7,0,12.3-2.3,16.7-6.7c4.4-4.4,6.9-10,6.9-17.5c0-7.5-2.5-13.2-6.9-17.5c-4.4-4.4-10-6.7-16.9-6.7
                            S795.4,1002.5,791.1,1006.9z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M976.8,1029.7h-69.5c0,5,1.9,11.7,5.6,15.9c4.6,5,10.9,5.8,15.4,5.8c4.4,0,9-0.8,12.1-2.7
                            c0.4-0.2,4-2.5,6.5-7.7l28.4,2.9c-4.2,13.2-12.5,20-18,23.4c-9,5.4-19,6.9-29.4,6.9c-14,0-26.3-2.5-37.2-13.4
                            c-8.1-8.1-14-20.5-14-35.9c0-13.4,4.8-27.1,14.2-36.7c11.3-11.3,25.3-13.4,35.7-13.4c10.4,0,25.5,1.9,36.9,14
                            c11.1,11.7,13.2,26.3,13.2,37.8V1029.7z M949.1,1011.7c-0.2-0.8-1.7-6.7-6.1-10.9c-3.3-3.1-8.1-5.2-14.2-5.2
                            c-7.7,0-12.3,3.1-15.2,6.1c-2.3,2.5-4.4,5.8-5.2,10H949.1z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1026.5,1002.1v68.9h-30.1v-68.9H986v-24.2h10.4v-30.1h30.1v30.1h18.6v24.2H1026.5z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1058,977.9h30.1v14.6c2.7-5.2,6.9-10,11.7-12.9c5-3.1,10.2-4,16.1-4c3.8,0,6.5,0.4,9.8,1.5l-1.7,28.2
                            c-4-2.1-8.3-3.3-13.6-3.3c-6.5,0-11.3,2.3-15,6.3c-6.7,7.1-7.3,17.1-7.3,25v37.8H1058V977.9z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1173.6,1059.1l-40.2-81.2h31.3l23.8,50.5l23.2-50.5h30.6l-67.7,140.9h-30.6L1173.6,1059.1z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1286.9,1118.8h-30.1V977.9h30.1V989c9.8-12.7,21.9-14.2,29-14.2c16.7,0,27.1,7.3,33,13.2
                            c8.3,8.3,14,21.1,14,36.3c0,16.1-5.6,28.2-14.8,37.4c-9.2,9.2-19.2,12.5-31.5,12.5c-18.6,0-26.5-9.4-29.6-13.8V1118.8z
                            M1291.9,1007.5c-4.8,4.8-7.1,11.5-7.1,17.1c0,5.8,2.3,11.9,6.7,16.5c4.4,4.4,10.6,7.1,17.1,7.1c5.6,0,11.7-2.3,16.1-6.5
                            c4.8-4.6,7.3-11.7,7.3-17.7c0-6.9-2.9-12.9-7.1-16.9c-4.4-4.2-10.2-6.5-16.3-6.5C1300.3,1000.7,1294.8,1004.6,1291.9,1007.5z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1382.5,918.8h30.1V989c3.8-4.6,7.7-7.7,11.3-9.6c6.3-3.3,11.5-4,18.2-4c7.3,0,18.2,1,26.3,9.4
                            c8.6,8.6,9.2,20,9.2,27.1v59.1h-30.1v-47.8c0-6.5-0.2-14-5-18.6c-2.7-2.7-7.3-4.2-11.7-4.2c-6.5,0-10.2,2.9-11.9,4.6
                            c-6.1,5.8-6.3,14.8-6.3,19.4v46.5h-30.1V918.8z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1518,920.7c9.8,0,17.5,7.7,17.5,17.5c0,9.8-7.7,17.5-17.5,17.5s-17.5-7.7-17.5-17.5
                            C1500.4,928.4,1508.2,920.7,1518,920.7z M1533,977.9v93.1H1503v-93.1H1533z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1589.4,918.8V1071h-30.1V918.8H1589.4z'
          />
          <path
            style={{ fill: '#413043' }}
            d='M1709,1029.7h-69.5c0,5,1.9,11.7,5.6,15.9c4.6,5,10.9,5.8,15.4,5.8c4.4,0,9-0.8,12.1-2.7
                            c0.4-0.2,4-2.5,6.5-7.7l28.4,2.9c-4.2,13.2-12.5,20-18,23.4c-9,5.4-19,6.9-29.4,6.9c-14,0-26.3-2.5-37.2-13.4
                            c-8.1-8.1-14-20.5-14-35.9c0-13.4,4.8-27.1,14.2-36.7c11.3-11.3,25.3-13.4,35.7-13.4s25.5,1.9,36.9,14
                            c11.1,11.7,13.2,26.3,13.2,37.8V1029.7z M1681.2,1011.7c-0.2-0.8-1.7-6.7-6.1-10.9c-3.3-3.1-8.1-5.2-14.2-5.2
                            c-7.7,0-12.3,3.1-15.2,6.1c-2.3,2.5-4.4,5.8-5.2,10H1681.2z'
          />
        </g>
      </g>
    </g>
    <g id='Layer_2'></g>
  </svg>
);

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newNotifications: 0,
      searchInput: '',
      isCreating: false
    };
  }
  componentDidMount() {
    console.log('HERE');
    M.Sidenav.init(this.sidenav);
    let dropdown = document.querySelector('.dropdown');
    this.dropdown = dropdown;
    M.Dropdown.init(dropdown);
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.onGetNewNotifications(authUser);
      }
    });
  }
  componentDidUpdate() {
    M.Sidenav.init(this.sidenav);
    let dropdown = document.querySelector('.dropdown');
    this.dropdown = dropdown;
    M.Dropdown.init(dropdown);
  }

  onCreatePoem = async (event, authUser) => {
    event.preventDefault();
    let currentUser = await this.props.firebase
      .user(this.props.firebase.auth.currentUser.uid)
      .get();
    this.props.firebase
      .poems()
      .add({
        poet: authUser,
        poetRef: this.props.firebase.db.collection('users').doc(authUser.uid),
        poetId: authUser.uid,
        type: 'draft',
        title: 'Untitled poem',
        tags: [{ tag: 'Poetryphile' }],
        text: '',
        ink: 0,
        inkers: [],
        views: 0,
        dailyInk: 0,
        weeklyInk: 0,
        monthlyInk: 0,
        format: 'format_align_left',
        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
        isClassic: currentUser.data().isFamous ? true : false
      })
      .then(async poem => {
        let newPoem = await this.props.firebase.poem(poem.id).get();
        this.setState({
          isCreating: false
        });
        await this.props.firebase.db
          .collection('poems')
          .doc('collectionInfo')
          .update({
            totalDrafts: this.props.firebase.fieldValue.increment(1),
            total: this.props.firebase.fieldValue.increment(1)
          });
        await this.props.firebase.db
          .collection('users')
          .doc(newPoem.data().poetId)
          .update({
            totalDrafts: this.props.firebase.fieldValue.increment(1),
            totalPoems: this.props.firebase.fieldValue.increment(1)
          });
        if (currentUser.data().isFamous) {
          await this.props.firebase.db
            .collection('poems')
            .doc('collectionInfo')
            .update({
              classics: this.props.firebase.fieldValue.increment(1)
            });
        }
        M.toast({ html: 'Poem created' });
        this.props.history.push(`/poems/${poem.id}/edit`);
      })
      .catch(err => {
        this.setState({ isCreating: false });

        M.toast({ html: 'Unable to create poem' });
      });
  };
  onGetTotalNew = () => {
    const parent = this;
    this.props.firebase
      .notifications()
      .where('poetId', '==', this.props.firebase.auth.currentUser.uid)
      .where('isRead', '==', false)
      .get()
      .then(function (querySnapshot) {
        parent.setState({
          newNotifications: querySnapshot.size
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  onGetNewNotifications = authUser => {
    const parent = this;
    this.onGetTotalNew();
    if (window.location.pathname == '/notifications') {
      parent.props.firebase
        .notifications()
        .where('poetId', '==', parent.props.firebase.auth.currentUser.uid)
        .where('isRead', '==', false)
        .get()
        .then(async function (querySnapshot) {
          for (var i in querySnapshot.docs) {
            const doc = await querySnapshot.docs[i];
            parent.props.firebase.db
              .collection('notifications')
              .doc(doc.id)
              .update({
                isRead: true
              });
          }
        })
        .then(() => {
          parent.onGetTotalNew();
        });
    }
  };

  render() {
    const NavigationAuth = props => (
      <div>
        <div>
          <AuthUserContext.Consumer>
            {authUser => (
              <div>
                <nav className='grey lighten-4 z-depth-0 nav-wrapper'>
                  <a
                    style={{ position: 'absolute' }}
                    href='#!'
                    className='sidenav-trigger search-hide'
                    data-target='mobile-menu'>
                    <i className='material-icons'>menu</i>
                  </a>
                  <div className='container'>
                    <a href={ROUTES.LANDING} className='brand-logo'>
                      <Logo />
                    </a>
                    <ul className='right'>
                      <li className='hide-on-med-and-down'>
                        <a href={ROUTES.BOOKMARKS} id='bookmark'>
                          <i className='material-icons nav-icon'>bookmark</i>
                        </a>
                      </li>
                      <a
                        style={{ marginTop: '-6px' }}
                        href='#!'
                        ref={dropdown => {
                          this.dropdown = dropdown;
                        }}
                        id='dropdown'
                        className='dropdown-trigger btn hide-on-med-and-down dropdown'
                        data-target='dropdown-items'>
                        <span className='futura'>{authUser.penName}</span>
                        <i id='dropdown-icon' className='material-icons right'>
                          arrow_drop_down
                        </i>
                      </a>
                    </ul>
                  </div>
                </nav>
                <ul id='dropdown-items' className='dropdown-content'>
                  <li>
                    <a
                      href='#!'
                      onClick={event => this.onCreatePoem(event, authUser)}>
                      Write a poem
                    </a>
                  </li>
                  <li>
                    <a href={ROUTES.MYPOEMS}>My poems</a>
                  </li>
                  <li className='divider' tabIndex='-1'></li>
                  <li>
                    <a href={`/poets/@${authUser.penName}`}>View profile</a>
                  </li>
                  <li>
                    <a href='/notifications'>
                      Notifications &nbsp;
                      <br />
                      <p
                        style={{ marginLeft: '0px' }}
                        className='new-notifications'>
                        {this.state.newNotifications} new
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTES.SETTINGS}>Settings</a>
                  </li>
                  <li>
                    <SignOut />
                  </li>
                </ul>
                <ul
                  ref={sidenav => {
                    this.sidenav = sidenav;
                  }}
                  className='sidenav grey lighten-4'
                  id='mobile-menu'>
                  <li>
                    <div className='user-view'>
                      <div
                        style={{ backgroundColor: '#413043' }}
                        className='background'></div>
                      <a className='sidenav-close' href='#!'>
                        <img
                          alt='Avatar'
                          style={{ border: '2px solid #da694b' }}
                          className='circle image'
                          src={authUser.avatar}></img>
                      </a>
                      <a className='sidenav-close' href='#!'>
                        <span
                          style={{
                            fontSize: '17px',
                            overflowWrap: 'break-word',
                            whiteSpace: 'pre-wrap'
                          }}
                          className='white-text name futura'>
                          {authUser.penName}
                        </span>
                      </a>
                      <a className='sidenav-close' href='#!'>
                        <span
                          style={{
                            fontSize: '16px',
                            overflowWrap: 'break-word',
                            whiteSpace: 'pre-wrap'
                          }}
                          className='white-text email'>
                          {authUser.email}
                        </span>
                      </a>
                    </div>
                  </li>
                  <li id='write-poem'>
                    <a
                      disabled={isCreating}
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href='#!'
                      onClick={event => this.onCreatePoem(event, authUser)}>
                      Write a poem
                    </a>
                  </li>
                  <li>
                    <a
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href={ROUTES.MYPOEMS}>
                      My poems
                    </a>
                  </li>
                  <li className='divider' tabIndex='-1'></li>
                  <li>
                    <a
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href='/bookmarks'>
                      Bookmarks
                    </a>
                  </li>
                  <li>
                    <a
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href='/notifications'>
                      Notifications
                      <span
                        id='new-small-badge'
                        style={{ color: 'white', background: '#da694b' }}
                        className='new badge'>
                        {this.state.newNotifications}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href={`/poets/@${authUser.penName}`}>
                      View profile
                    </a>
                  </li>
                  <li>
                    <a
                      className='sidenav-close'
                      style={{ fontSize: '16px' }}
                      href={ROUTES.SETTINGS}>
                      Settings
                    </a>
                  </li>
                  <li>
                    <SignOut />
                  </li>
                </ul>
              </div>
            )}
          </AuthUserContext.Consumer>
        </div>
      </div>
    );
    const NavigationNonAuth = () => (
      <div>
        <nav className='grey lighten-4 z-depth-0 nav-wrapper'>
          <a href='#!' className='sidenav-trigger' data-target='mobile-menu'>
            <i className='material-icons'>menu</i>
          </a>
          <div className='container'>
            <a href={ROUTES.LANDING} className='brand-logo'>
              <Logo />
            </a>
            <ul className='right'>
              <li>
                <a
                  href={ROUTES.ABOUT}
                  className='primary-nav-link hide-on-med-and-down'>
                  About
                </a>
              </li>
              <li>
                <a
                  href='#!'
                  data-target='modal-login'
                  className='modal-trigger primary-nav-link'>
                  Login
                </a>
              </li>
            </ul>
          </div>
          <ul
            ref={sidenav => {
              this.sidenav = sidenav;
            }}
            className='sidenav grey lighten-4'
            id='mobile-menu'>
            <li>
              <a href={ROUTES.ABOUT} className='sidenav-close side-nav-link'>
                About
              </a>
            </li>
          </ul>
        </nav>
        <Login />
        <SignUp />
        <ForgotPassword />
      </div>
    );
    const { newNotifications, searchInput, isCreating } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <NavigationAuth
              newNotifications={newNotifications}
              searchInput={searchInput}
              isCreating={isCreating}
            />
          ) : (
            <NavigationNonAuth />
          )
        }
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(withFirebase, withRouter)(Navigation);
