import React from 'react';

const TheEnd = () => {
  return (
    <li id='the-end' className='collection-item'>
      <center>
        <svg
          style={{ width: '300px', marginTop: '-70px' }}
          xmlnsXlink='http://www.w3.org/2000/svg'
          viewBox='0 0 2000 2000'>
          <title>Poetryphile The End</title>
          <g id='Layer_2' data-name='Layer 2'>
            <path
              d='M741.55,1312.64v83.95H713.62v-83.95H690.7v-23.73h73.77v23.73Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M771.25,1288.91h25.83v44.56a31.6,31.6,0,0,1,8.07-7.11,29.16,29.16,0,0,1,14.86-3.55c10.17,0,17,3.39,21.31,7.75,6.45,6.46,6.78,13.24,6.78,20.18v45.85H822.27V1360.1c0-5.32-.33-10.81-3.23-14-2.42-2.75-5.65-3.07-8.24-3.07s-6.45.48-9.36,3.07c-2.26,2.1-4.36,6-4.36,11.78v38.75H771.25Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M939,1365.27H885.22c0,3.87,1.46,9,4.53,12.11,1.61,1.61,4.84,3.55,10.65,3.55a20.34,20.34,0,0,0,8.4-1.94,12.12,12.12,0,0,0,4.52-4.84H938A32.43,32.43,0,0,1,929.3,1389c-5.33,5.17-13.72,10-29.38,10-9.53,0-21-2.1-29.87-11-4.68-4.68-10.66-13.56-10.66-27.28,0-12.11,4.53-21.8,10.82-27.93,6-5.81,15.34-10.49,29.54-10.49,8.4,0,19.22,1.77,27.77,9.84,10.17,9.69,11.46,22.28,11.46,30.35Zm-23.73-14.85a17.23,17.23,0,0,0-4.2-7.75c-3.38-3.39-8.07-3.88-10.65-3.88-4.36,0-7.75,1.13-10.49,3.88a14.76,14.76,0,0,0-3.88,7.75Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1055.05,1312.64h-33.26v17.92h31.48v23.73h-31.48v18.57h33.26v23.73H993.87V1288.91h61.18Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1070.38,1324.43h25.84v9c7.1-9.37,16.62-10.66,22.92-10.66,7.43,0,15.34,1.78,21.31,7.75,6.13,6.13,6.78,12.27,6.78,20.18v45.85H1121.4V1360.1c0-4.19.16-10.49-3.23-14-2.42-2.59-5.65-3.07-8.23-3.07a14.13,14.13,0,0,0-9.53,3.39c-2.58,2.42-4.19,7.1-4.19,11.46v38.75h-25.84Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1246.51,1396.59h-25.83v-7.91c-7.27,9.36-16.79,10.33-21.8,10.33-10.33,0-18.56-3.39-25.34-10a39.77,39.77,0,0,1-11.62-28.74,39.32,39.32,0,0,1,11.78-28.57c7.1-6.78,16.63-9.69,25.51-9.69a28.52,28.52,0,0,1,21.47,9.37v-42.46h25.83ZM1216,1372.7a17.33,17.33,0,0,0,5.49-12.27,18.87,18.87,0,0,0-4.85-11.63,16.56,16.56,0,0,0-23.24-.16,17.72,17.72,0,0,0-5,12.27,16.32,16.32,0,0,0,5,11.3,16,16,0,0,0,11.46,4.84A17.65,17.65,0,0,0,1216,1372.7Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1271.21,1394.65a16.79,16.79,0,0,1,11.78-28.57,17,17,0,0,1,12,5.16,16.77,16.77,0,0,1-.16,23.41,16.54,16.54,0,0,1-11.79,5A16.34,16.34,0,0,1,1271.21,1394.65Zm-1.78-34.71v-71h26.8v71Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M323.29,1083.18S827.83,646,866.19,809s-44.26,319.54,153.43,164.09,218.34-93.92,251.78-8.64,147.53-8.63,267.52-10.79'
              style={{
                fill: 'none',
                stroke: '#413043',
                strokeLinecap: 'round',
                strokeMiterlimit: '10',
                strokeWidth: '45.337942614792325px'
              }}
            />
            <line
              x1='1669.55'
              y1='940.47'
              x2='1676.71'
              y2='940.47'
              style={{
                fill: 'none',
                stroke: '#413043',
                strokeLinecap: 'round',
                strokeMiterlimit: '10',
                strokeWidth: '45.337942614792325px'
              }}
            />
          </g>
        </svg>
      </center>
    </li>
  );
};

export default TheEnd;
