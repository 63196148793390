import React, { Component } from 'react';

class LoginRequired extends Component {
  render() {
    return (
      <div>
        <center>
          <svg
            id='Layer_1'
            style={{ width: '400px' }}
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 2000 2000'>
            <title>Poetryphile Locked Poem</title>
            <path
              d='M1325.05,1243.78a54.17,54.17,0,0,0,54.17-54.17V1086.5l-114.85,157.28Zm0-433.39h-13.36l67.53,92.52V864.56A54.18,54.18,0,0,0,1325.05,810.39ZM620.78,1189.61A54.17,54.17,0,0,0,675,1243.78h60.44L620.78,1087ZM675,810.39a54.18,54.18,0,0,0-54.17,54.17v38.17l67.48-92.34Z'
              style={{ fill: '#413043' }}
            />
            <path
              d='M1401.67,787.94a108.21,108.21,0,0,0-76.62-31.73c0-167.21-126.35-305-288.81-323-8-.9-16-1.5-24.08-1.8-4-.18-8.07-.24-12.16-.24s-8.37.06-12.52.3h-.06c-7.89.24-15.71.84-23.42,1.68C801.42,451,675,588.88,675,756.21A108.35,108.35,0,0,0,566.61,864.56v325A108.35,108.35,0,0,0,675,1298H775l198,270.87H1027L1224.82,1298h100.23a108.36,108.36,0,0,0,108.35-108.35v-325A108.21,108.21,0,0,0,1401.67,787.94Zm-22.45,76.62v38.35l-67.53-92.52h13.36A54.18,54.18,0,0,1,1379.22,864.56Zm-650.09-110v-.06c.78-119.55,79-220.73,187-255.88h.06A271.8,271.8,0,0,1,993,485.4c2.34,0,4.69-.06,7-.06s4.88.06,7.28.06A269.73,269.73,0,0,1,1084,498.65C1192,533.86,1270.15,635,1270.87,754.53l1.27,1.68H727.86Zm-108.35,110A54.18,54.18,0,0,1,675,810.39h13.31l-67.48,92.34ZM675,1243.78a54.17,54.17,0,0,1-54.17-54.17V1087l114.61,156.8Zm704.27-54.17a54.17,54.17,0,0,1-54.17,54.17h-60.68l114.85-157.28Zm.06-194.79-.06-.06-181.9,249L1157.71,1298l-130.62,178.89V1103.71a81.26,81.26,0,1,0-54.18,0v373.2l-130.8-179-39.61-54.18L620.78,995.12v-.54L755.43,810.39h489.14l134.65,184.25.06-.06.18.12Z'
              style={{ fill: '#413043' }}
            />
            <polygon
              points='727.86 756.21 729.13 754.53 729.13 756.21 727.86 756.21'
              style={{ fill: '#413043' }}
            />
            <polygon
              points='1272.14 756.21 1270.87 756.21 1270.87 754.53 1272.14 756.21'
              style={{ fill: '#413043' }}
            />
            <path
              d='M1379.28,994.58l-.06.06v.12l.06.06.18-.12Z'
              style={{ fill: '#413043' }}
            />
            <path
              d='M688.26,810.39l-67.48,92.34V864.56A54.18,54.18,0,0,1,675,810.39Z'
              style={{ fill: '#413043' }}
            />
            <path
              d='M735.39,1243.78H675a54.17,54.17,0,0,1-54.17-54.17V1087Z'
              style={{ fill: '#413043' }}
            />
            <polygon
              points='729.13 754.53 729.13 756.21 727.86 756.21 729.13 754.53'
              style={{ fill: '#413043' }}
            />
            <path
              d='M1379.22,1086.5v103.11a54.17,54.17,0,0,1-54.17,54.17h-60.68Z'
              style={{ fill: '#413043' }}
            />
            <polygon
              points='1379.46 994.7 1379.28 994.82 1379.22 994.76 1379.22 994.64 1379.28 994.58 1379.46 994.7'
              style={{ fill: '#413043' }}
            />
            <path
              d='M1379.22,864.56v38.35l-67.53-92.52h13.36A54.18,54.18,0,0,1,1379.22,864.56Z'
              style={{ fill: '#413043' }}
            />
            <path
              d='M620.78,1087v102.63A54.17,54.17,0,0,0,675,1243.78h60.44ZM675,810.39a54.18,54.18,0,0,0-54.17,54.17v38.17l67.48-92.34Zm650.1,0h-13.36l67.53,92.52V864.56A54.18,54.18,0,0,0,1325.05,810.39Zm-60.68,433.39h60.68a54.17,54.17,0,0,0,54.17-54.17V1086.5Z'
              style={{ fill: '#da694b' }}
            />
            <polygon
              points='1272.14 756.21 1270.87 756.21 1270.87 754.53 1272.14 756.21'
              style={{ fill: '#413043' }}
            />
            <polygon
              points='1379.46 994.7 1379.28 994.82 1379.22 994.76 1379.22 994.64 1379.28 994.58 1379.46 994.7'
              style={{ fill: '#413043' }}
            />
          </svg>
          <h4 id='no-login' className='futura'>
            Please Login!
          </h4>
        </center>
      </div>
    );
  }
}

export default LoginRequired;
