import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import { withRouter } from 'react-router-dom';
import Modal from '../partials/Modal';

import { withFirebase } from '../config';

const SignUp = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  penName: '',
  loading: false
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try to login with this account instead.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    M.Modal.init(this.modal);
    const switchToLoginModal = document.querySelector('#switch-to-login');
    if (switchToLoginModal) {
      switchToLoginModal.addEventListener('click', () => {
        document.querySelector('#modal-signup').M_Modal.close();
        setTimeout(() => {
          document.querySelector('#modal-login').M_Modal.open();
        }, 150);
      });
    }
  }

  onSubmit = event => {
    event.preventDefault();

    const { email, password, penName } = this.state;

    const filteredPenName = penName;

    this.setState({ loading: true });

    this.props.firebase.db
      .collection('users')
      .where('penName', '==', filteredPenName)
      .get()
      .then(
        function (querySnapshot) {
          if (querySnapshot.empty) {
            if (filteredPenName.length > 15) {
              this.setState({ loading: false });
              M.toast({
                html: 'Your pen name must be less than 15 characters'
              });
            } else {
              this.props.firebase
                .doCreateUserWithEmailAndPassword(email, password)
                .then(authUser => {
                  // Create a user in your Firebase firestore database
                  document.querySelector('#modal-signup').M_Modal.close();
                  return this.props.firebase.user(authUser.user.uid).set(
                    {
                      email,
                      bookmarks: [],
                      ink: 0,
                      dailyInk: 0,
                      weeklyInk: 0,
                      monthlyInk: 0,
                      saved: [],
                      bio: '',
                      penName: filteredPenName,
                      dateJoined: this.props.firebase.fieldValue.serverTimestamp(),
                      followers: [],
                      following: [],
                      totalFollowers: 0,
                      totalFollowing: 0,
                      totalPoems: 0,
                      isFamous: false,
                      totalDrafts: 0,
                      totalPublic: 0,
                      totalPoems: 0,
                      queryable: true,
                      hideExplicit: false,
                      avatar:
                        'https://firebasestorage.googleapis.com/v0/b/poetryphile-89b6b.appspot.com/o/images%2Ffeather.jpg?alt=media&token=22788f2e-d6b8-44f8-a205-3778d27c283d'
                    },
                    { merge: true }
                  );
                })
                .then(() => {
                  this.setState({ ...INITIAL_STATE });
                  M.toast({ html: 'Welcome to Poetryphile' });
                  this.props.firebase.db
                    .collection('users')
                    .doc('collectionInfo')
                    .update({
                      total: this.props.firebase.fieldValue.increment(1)
                    });
                })
                .catch(error => {
                  if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                    error.message = ERROR_MSG_ACCOUNT_EXISTS;
                  }
                  this.setState({ loading: false });
                  M.toast({ html: error.message });
                });
            }
          } else {
            this.setState({ loading: false });
            M.toast({ html: 'A poet already has this pen name' });
          }
        }.bind(this)
      )
      .catch(error => {
        this.setState({ loading: false });
        M.toast({ html: 'Failed to signup' });
      });
  };

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { email, password, penName, loading } = this.state;
    const isInvalid =
      password.replace(/\s/g, '') === '' ||
      email.replace(/\s/g, '') === '' ||
      penName.replace(/\s/g, '') === '' ||
      loading === true;
    return (
      <Modal
        signUp={true}
        onSubmit={this.onSubmit}
        isInvalid={isInvalid}
        loading={loading}
        onChange={this.onChange}
      />
    );
  }
}

const SignUpForm = compose(withFirebase, withRouter)(SignUpFormBase);

export default SignUp;

export { SignUpForm };
