import React from 'react';

const Preloader = () => {
  return (
    <div className='frame'>
      <div className='center'>
        <div className='ball'></div>
        <div className='blubb-1'></div>
        <div className='blubb-2'></div>
        <div className='blubb-3'></div>
        <div className='blubb-4'></div>
        <div className='blubb-5'></div>
        <div className='blubb-6'></div>
        <div className='blubb-7'></div>
        <div className='blubb-8'></div>
        <div className='sparkle-1'></div>
        <div className='sparkle-2'></div>
        <div className='sparkle-3'></div>
        <div className='sparkle-4'></div>
        <div className='sparkle-5'></div>
        <div className='sparkle-6'></div>
        <div className='sparkle-7'></div>
        <div className='sparkle-8'></div>
        <div className='sparkle-9'></div>
        <div className='sparkle-10'></div>
      </div>
    </div>
  );
};

export default Preloader;
