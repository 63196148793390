import React from 'react';

import { withFirebase } from '../config';

const SignOutButton = ({ firebase }) => (
  <a
    style={{ fontSize: '16px' }}
    href='#!'
    className='sidenav-close'
    onClick={firebase.doSignOut}>
    Sign Out
  </a>
);

export default withFirebase(SignOutButton);
