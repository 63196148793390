import React, { Component } from 'react';

export default class FourOFour extends Component {
  render() {
    return (
      <div className='container'>
        <center>
          <svg
            style={{ width: '400px' }}
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 2000 2000'>
            <title>Poetryphile 404</title>
            <path
              d='M1633.44,1335.27c0,173.54-317.26,106.12-423.38,0-58.19-58.2-133.12-23.28-112.29,66.36,41.17,177.21-166.2,138.85-137.81,5.1,23.32-109.84-132.71-97-239.89,10.21s-335.29,50.21-352.18-122.5c-18-183.75,148.46-162.18,296-173.54,119.05-9.16,57.31-115.4,0-116.83-66.33-1.66-342-169-132.7-378.26C731.4,425.63,917.57,594.42,933.35,806q1.08,14.41,1.09,29c0,75,15.36,130.36,35.83,166,40.83,71.14,102,63.75,102-23.09,0,0-.36-7-.35-19a686.67,686.67,0,0,1,9.47-112.24c17.24-100.11,65-221,195-221,222,0,91.87,309.92,0,378.26s-15.31,142.35,71.46,127S1633.44,1161.73,1633.44,1335.27Z'
              style={{ fill: '#413043' }}
            />
            <circle
              cx='1192.36'
              cy='1521.12'
              r='50.07'
              style={{ fill: '#413043' }}
            />
            <circle
              cx='1441.39'
              cy='952.32'
              r='35.4'
              style={{ fill: '#413043' }}
            />
            <circle
              cx='878.15'
              cy='496.37'
              r='67.56'
              style={{ fill: '#413043' }}
            />
            <path
              d='M144.33,1187.28v82.21H117v-82.21H94.53V1164h72.25v23.24Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M173.42,1154.24h25.3v53.43a30.8,30.8,0,0,1,7.9-7,28.43,28.43,0,0,1,14.55-3.48c10,0,16.6,3.32,20.87,7.59,6.32,6.32,6.64,13,6.64,19.76v44.9h-25.3v-35.73c0-5.22-.32-10.59-3.16-13.75-2.37-2.69-5.54-3-8.06-3-2.69,0-6.33.48-9.17,3-2.22,2-4.27,5.84-4.27,11.54v37.94h-25.3Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M274.61,1155.19a14.23,14.23,0,1,1-14.23,14.22A14.16,14.16,0,0,1,274.61,1155.19Zm12.64,43.63v70.67H262v-70.67Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M309.07,1246.09a36.45,36.45,0,0,0,10.12,5.22,32.78,32.78,0,0,0,9.8,1.74c1.9,0,4.9-.48,6.48-1.9a4.06,4.06,0,0,0,1.27-3.32,3.74,3.74,0,0,0-1.27-3.16c-1.58-1.42-4.11-1.9-6.64-2.53l-6.64-1.58c-3.47-.79-8.53-2.06-12.33-6.17a17.07,17.07,0,0,1-4.58-12.17,25.25,25.25,0,0,1,6.64-17.23c5.37-5.54,14.86-8.54,25.29-8.54a58.5,58.5,0,0,1,22.61,4.58l-8.54,16.13c-3.48-1.58-8.69-3.48-13.28-3.48a10.75,10.75,0,0,0-5.85,1.42,3.8,3.8,0,0,0-2.21,3.48c0,2.22,1.42,3.32,3,4,2.38,1,4.43,1,8.38,2.06l4.59,1.26c3.32,1,8.38,3.48,10.43,5.54,3.48,3.32,5.38,8.85,5.38,14.07A23.61,23.61,0,0,1,354.6,1263c-8.22,8.22-20.23,8.85-26.08,8.85-6.33,0-16.29-.79-28.46-8.22Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M436.81,1305.69H411.52V1198.82h25.29v7.75c4.43-5.54,11.23-10.12,21.66-10.12a35.13,35.13,0,0,1,25.14,10.27c5.85,5.85,10.75,15.18,10.75,27.36,0,11.85-4.59,21.34-11.38,28a34,34,0,0,1-24.19,9.8,30.09,30.09,0,0,1-22-9.17Zm4.11-83a17.25,17.25,0,0,0-4.9,11.7,17.06,17.06,0,0,0,4.9,11.23,16.2,16.2,0,0,0,11.38,4.74,15.29,15.29,0,0,0,11.23-4.74,16.73,16.73,0,0,0,4.9-11.23,17.25,17.25,0,0,0-4.9-11.7A15.29,15.29,0,0,0,452.3,1218,16.2,16.2,0,0,0,440.92,1222.69Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M563.61,1198.82H588.9v70.67H563.61v-7.75c-5.85,8.07-15,10.12-22,10.12-7.59,0-16.92-2.21-25.45-11.22-6.8-7.28-10.12-15.34-10.12-25.93,0-13.28,5.22-22.61,10.91-28.3,6-6,15.33-10,25.45-10a28,28,0,0,1,21.19,9.17Zm-26.56,23.71a16.54,16.54,0,0,0-5.06,11.55,16.32,16.32,0,0,0,4.9,11.54,17.75,17.75,0,0,0,11.38,4.74,16.22,16.22,0,0,0,11.38-4.9,15.31,15.31,0,0,0,4.75-11.38,15.66,15.66,0,0,0-4.9-11.55,16,16,0,0,0-22.45,0Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M685.82,1198.82v66.24c0,10.6-1,20.24-9.65,29.25-9,9.17-20.87,11.38-31.14,11.38-13.12,0-24-3.32-33-12.49-4.11-4.26-6.8-8.53-7.75-14.7h28.14c1,2.85,3.48,7.27,11.86,7.27,4,0,7.9-.79,11.22-4,4.27-4,5.06-9,5.06-13.12v-7.12c-7.9,8.38-15.49,9.33-21.18,9.33-13,0-21.35-5.06-26.09-10-6.32-6.48-9.8-16.12-9.8-25.77,0-11.06,4.27-21.18,10.43-27.66a35.52,35.52,0,0,1,25.61-10.76,27.59,27.59,0,0,1,21,9.33v-7.27Zm-51.54,23.71a15.86,15.86,0,0,0,0,22.93,16,16,0,0,0,11.38,4.58,16.2,16.2,0,0,0,16.13-16.12,18.16,18.16,0,0,0-5.06-11.54,16,16,0,0,0-11.23-4.59A15.1,15.1,0,0,0,634.28,1222.53Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M778.14,1238.82H725.5c0,3.79,1.42,8.85,4.43,11.86,1.58,1.58,4.74,3.47,10.43,3.47a19.89,19.89,0,0,0,8.22-1.89,11.79,11.79,0,0,0,4.43-4.75H777.2a31.76,31.76,0,0,1-8.54,14.55c-5.22,5.06-13.44,9.8-28.77,9.8-9.33,0-20.56-2-29.25-10.75-4.59-4.58-10.44-13.28-10.44-26.72,0-11.86,4.43-21.34,10.6-27.35,5.85-5.69,15-10.28,28.93-10.28,8.22,0,18.81,1.74,27.19,9.65,10,9.48,11.22,21.82,11.22,29.72Zm-23.24-14.55a16.88,16.88,0,0,0-4.11-7.58c-3.32-3.32-7.9-3.8-10.43-3.8-4.27,0-7.59,1.11-10.28,3.8a14.34,14.34,0,0,0-3.79,7.58Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M909.52,1269.49H884.23v-7.75a25.93,25.93,0,0,1-21.35,10.12c-10.11,0-18.18-3.32-24.82-9.8a39,39,0,0,1-11.38-28.14,38.53,38.53,0,0,1,11.54-28c7-6.63,16.29-9.48,25-9.48a28,28,0,0,1,21,9.17v-51.38h25.29Zm-29.88-23.4a17,17,0,0,0,5.38-12,18.55,18.55,0,0,0-4.74-11.39,16.24,16.24,0,0,0-22.77-.16,17.36,17.36,0,0,0-4.9,12,16,16,0,0,0,4.9,11.07,15.73,15.73,0,0,0,11.22,4.74A17.27,17.27,0,0,0,879.64,1246.09Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M998.06,1260.16c-9.33,9.17-20.24,11.7-31.62,11.7-10.28,0-21.66-2-31.46-11.7a36.45,36.45,0,0,1,0-52c5.85-5.69,16.12-11.7,31.46-11.7,15.65,0,25.77,6.16,31.62,11.7a34.92,34.92,0,0,1,10.91,25.93A35.29,35.29,0,0,1,998.06,1260.16Zm-42.85-37.63A16.13,16.13,0,0,0,966.6,1250a16.07,16.07,0,0,0,11.22-4.74,15.58,15.58,0,0,0,4.9-11.22,17.2,17.2,0,0,0-4.9-11.55,16.24,16.24,0,0,0-22.61,0Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1098.45,1238.82h-52.64c0,3.79,1.42,8.85,4.42,11.86,1.58,1.58,4.74,3.47,10.44,3.47a19.89,19.89,0,0,0,8.22-1.89,11.71,11.71,0,0,0,4.42-4.75h24.19a31.73,31.73,0,0,1-8.53,14.55c-5.22,5.06-13.44,9.8-28.78,9.8-9.33,0-20.55-2-29.25-10.75-4.58-4.58-10.43-13.28-10.43-26.72,0-11.86,4.43-21.34,10.59-27.35,5.85-5.69,15-10.28,28.93-10.28,8.23,0,18.82,1.74,27.2,9.65,10,9.48,11.22,21.82,11.22,29.72Zm-23.24-14.55a16.88,16.88,0,0,0-4.11-7.58c-3.32-3.32-7.9-3.8-10.43-3.8-4.27,0-7.59,1.11-10.28,3.8a14.34,14.34,0,0,0-3.79,7.58Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1117.58,1246.09a36.45,36.45,0,0,0,10.12,5.22,32.78,32.78,0,0,0,9.8,1.74c1.9,0,4.9-.48,6.48-1.9a4.06,4.06,0,0,0,1.27-3.32,3.74,3.74,0,0,0-1.27-3.16c-1.58-1.42-4.11-1.9-6.64-2.53l-6.64-1.58c-3.47-.79-8.53-2.06-12.33-6.17a17.07,17.07,0,0,1-4.58-12.17,25.25,25.25,0,0,1,6.64-17.23c5.37-5.54,14.86-8.54,25.29-8.54a58.5,58.5,0,0,1,22.61,4.58l-8.54,16.13c-3.47-1.58-8.69-3.48-13.28-3.48a10.78,10.78,0,0,0-5.85,1.42,3.8,3.8,0,0,0-2.21,3.48c0,2.22,1.42,3.32,3,4,2.38,1,4.43,1,8.38,2.06l4.59,1.26c3.32,1,8.38,3.48,10.43,5.54,3.48,3.32,5.38,8.85,5.38,14.07a23.61,23.61,0,0,1-7.12,17.55c-8.22,8.22-20.23,8.85-26.08,8.85-6.33,0-16.29-.79-28.46-8.22Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1220,1198.82h25.29v8.85c7-9.17,16.29-10.43,22.45-10.43,7.27,0,15,1.74,20.87,7.59,6,6,6.64,12,6.64,19.76v44.9H1270v-35.73c0-4.11.16-10.28-3.16-13.75a10.59,10.59,0,0,0-8.06-3,13.85,13.85,0,0,0-9.33,3.32c-2.53,2.37-4.11,7-4.11,11.23v37.94H1220Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1383.66,1260.16c-9.33,9.17-20.24,11.7-31.62,11.7-10.28,0-21.66-2-31.46-11.7a36.45,36.45,0,0,1,0-52c5.85-5.69,16.12-11.7,31.46-11.7,15.65,0,25.77,6.16,31.62,11.7a36.45,36.45,0,0,1,0,52Zm-42.85-37.63A16.13,16.13,0,0,0,1352.2,1250a16.07,16.07,0,0,0,11.22-4.74,15.58,15.58,0,0,0,4.9-11.22,17.2,17.2,0,0,0-4.9-11.55,16.24,16.24,0,0,0-22.61,0Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1437.09,1219.53v50H1411.8v-50h-8.06v-20.71h8.06v-23.24h25.29v23.24h14.39v20.71Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1572.74,1238.82h-52.65c0,3.79,1.43,8.85,4.43,11.86,1.58,1.58,4.74,3.47,10.43,3.47a19.93,19.93,0,0,0,8.23-1.89,11.71,11.71,0,0,0,4.42-4.75h24.19a31.76,31.76,0,0,1-8.54,14.55c-5.21,5.06-13.43,9.8-28.77,9.8-9.33,0-20.55-2-29.25-10.75-4.58-4.58-10.43-13.28-10.43-26.72,0-11.86,4.42-21.34,10.59-27.35,5.85-5.69,15-10.28,28.93-10.28,8.22,0,18.82,1.74,27.19,9.65,10,9.48,11.23,21.82,11.23,29.72Zm-23.24-14.55a16.88,16.88,0,0,0-4.11-7.58c-3.32-3.32-7.91-3.8-10.44-3.8-4.26,0-7.58,1.11-10.27,3.8a14.42,14.42,0,0,0-3.8,7.58Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1612.27,1231.07l-28.62-32.25h32.88l12.18,14.55,12.49-14.55h32.72l-29.24,32.25,35.41,38.42h-33.52l-18-21-18,21H1577Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1701.43,1155.19a14.23,14.23,0,1,1-14.23,14.22A14.16,14.16,0,0,1,1701.43,1155.19Zm12.65,43.63v70.67h-25.29v-70.67Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1735.9,1246.09a36.31,36.31,0,0,0,10.12,5.22,32.78,32.78,0,0,0,9.8,1.74c1.9,0,4.9-.48,6.48-1.9a4.06,4.06,0,0,0,1.27-3.32,3.74,3.74,0,0,0-1.27-3.16c-1.58-1.42-4.11-1.9-6.64-2.53l-6.64-1.58c-3.48-.79-8.54-2.06-12.33-6.17a17.08,17.08,0,0,1-4.59-12.17,25.25,25.25,0,0,1,6.64-17.23c5.38-5.54,14.87-8.54,25.3-8.54a58.55,58.55,0,0,1,22.61,4.58l-8.54,16.13c-3.48-1.58-8.69-3.48-13.28-3.48a10.75,10.75,0,0,0-5.85,1.42,3.8,3.8,0,0,0-2.21,3.48c0,2.22,1.42,3.32,3,4,2.37,1,4.43,1,8.38,2.06l4.58,1.26c3.32,1,8.38,3.48,10.44,5.54,3.48,3.32,5.37,8.85,5.37,14.07a23.61,23.61,0,0,1-7.11,17.55c-8.22,8.22-20.24,8.85-26.09,8.85-6.32,0-16.28-.79-28.45-8.22Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1829.49,1219.53v50H1804.2v-50h-8.07v-20.71h8.07v-23.24h25.29v23.24h14.39v20.71Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1860.16,1267.59a16.44,16.44,0,0,1,11.54-28,16.61,16.61,0,0,1,11.7,5.06,16.4,16.4,0,0,1-.16,22.92,16.19,16.19,0,0,1-11.54,4.9A16,16,0,0,1,1860.16,1267.59Zm-1.74-34V1164h26.25v69.56Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M863.91,916h26.34v43.25H863.91v41h-52v-41h-105V923.17l83.57-139.83h73.49Zm-52,0V827.57l-51,88.44Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1081.37,846.76A686.67,686.67,0,0,0,1071.9,959c10.08-18,16-40.92,16-67A150.7,150.7,0,0,0,1081.37,846.76ZM933.35,806c-18,19.36-29.43,49.4-29.43,85.92,0,38,12.67,69.26,32.19,88.45a86.29,86.29,0,0,0,34.16,20.68c-20.47-35.65-35.83-91-35.83-166Q934.44,820.46,933.35,806Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1055.76,803.17a84.8,84.8,0,0,0-119.65,0c-1,.92-1.86,1.88-2.76,2.86q1.08,14.41,1.09,29c0,75,15.36,130.36,35.83,166a84,84,0,0,0,25.66,4,85.54,85.54,0,0,0,59.83-24.72A94.48,94.48,0,0,0,1071.9,959a686.67,686.67,0,0,1,9.47-112.24C1075.78,828.94,1066.87,814.11,1055.76,803.17Zm-42.59,145.69a25.19,25.19,0,0,1-34.47,0c-12.68-11.05-18.86-39.36-18.86-56.58,0-20.16,7.15-46.5,18.86-56.58a25.16,25.16,0,0,1,34.47,0c11.38,10.08,18.86,37.72,18.86,56.9C1032,911.14,1025.2,938.46,1013.17,948.86Z'
              style={{ fill: '#da694b' }}
            />
            <path
              d='M1262.25,916h26.34v43.25h-26.34v41h-52v-41h-105V923.17l83.57-139.83h73.49Zm-52,0V827.57l-51,88.44Z'
              style={{ fill: '#da694b' }}
            />
          </svg>
        </center>
      </div>
    );
  }
}
