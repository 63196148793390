import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import M from 'materialize-css';

import Navigation from './components/partials/Navigation';
import Landing from './components/Landing';
import About from './components/About';
import MyPoems from './components/poems/MyPoems';
import EditPoem from './components/poems/EditPoem';
import ShowPoem from './components/poems/ShowPoem';
import FourOFour from './components/partials/FourOFour';
import Profile from './components/users/Profile';
import Settings from './components/users/Settings';
import Bookmarks from './components/users/Bookmarks';
import Notifications from './components/users/Notifications';
import Tos from './components/Tos';
import Privacy from './components/Privacy';
import Following from './components/browse/Following';
import New from './components/browse/New';
import Ink from './components/browse/Ink';

import { withAuthentication } from './components/session';
import * as ROUTES from './constants/routes';
import Contests from './components/browse/Contests';

class App extends Component {
  componentDidMount() {
    if (this.getQueryVariable('msg') == 1) {
      M.toast({
        html: 'Welcome back to Poetryphile!'
      });
    }
    if (this.getQueryVariable('msg') == 2) {
      M.toast({
        html: 'Welcome to Poetryphile!'
      });
    }
  }
  getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return '';
  };
  render() {
    return (
      <div>
        <Router>
          <div>
            <Navigation />

            <div>
              <Switch>
                <Route
                  exact
                  path={ROUTES.LANDING}
                  render={props => (
                    <Landing {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.ABOUT}
                  render={props => (
                    <About {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.MYPOEMS}
                  render={props => (
                    <MyPoems {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.EDITPOEM}
                  render={props => (
                    <EditPoem {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.SHOWPOEM}
                  render={props => (
                    <ShowPoem {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.NEW}
                  render={props => (
                    <New {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.INK}
                  render={props => (
                    <Ink {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.SETTINGS}
                  render={props => (
                    <Settings {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.PROFILE}
                  render={props => (
                    <Profile {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.BOOKMARKS}
                  render={props => (
                    <Bookmarks {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.NOTIFICATIONS}
                  render={props => (
                    <Notifications {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.TOS}
                  render={props => (
                    <Tos {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.PRIVACY}
                  render={props => (
                    <Privacy {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.FOLLOWING}
                  render={props => (
                    <Following {...props} key={window.location.pathname} />
                  )}
                />

                <Route
                  exact
                  path={ROUTES.CONTESTS}
                  render={props => (
                    <Contests {...props} key={window.location.pathname} />
                  )}
                />

                <Route component={FourOFour} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default withAuthentication(App);
