import React, { Component } from 'react';
import M from 'materialize-css';
import $ from 'jquery';

class Modal extends Component {
  componentDidMount() {
    const options = {
      onOpenStart: () => {
        console.log('Open Start');
      },
      onOpenEnd: () => {
        console.log('Open End');
      },
      onCloseStart: () => {
        console.log('Close Start');
      },
      onCloseEnd: () => {
        console.log('Close End');
      },
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: '4%',
      endingTop: '10%'
    };

    if (this.props.editPage != undefined) {
      M.Chips.init(this.chips, {
        data: this.props.tags,
        placeholder: 'Tags(optional)',
        secondaryPlaceholder: '+Add more',
        autocompleteOptions: {
          data: {
            love: null
          },
          limit: 2,
          minLength: 1
        }
      });
    }

    M.Modal.init(this.Modal, options);
  }

  render() {
    if (this.props.inkerModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id={this.props.poem.uid + 'modal-inkers'}
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Inkers&nbsp;
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#413043'
                }}>{`(${this.props.poem.ink})`}</span>
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            {this.props.poem.inkers.length > 0 ? (
              <div>
                {this.props.poem.inkers.map((inker, index) => (
                  <div key={index}>
                    <a
                      style={{ fontSize: '17px' }}
                      href={`/poets/@${inker.inker.penName}`}
                      className='inker'>
                      {inker.inker.penName}
                      <span>
                        <div className='ink-line ink-score'>
                          <span
                            style={{
                              color: '#da694b',
                              display: 'inline-block',
                              paddingRight: '3px',
                              paddingLeft: '5px'
                            }}>
                            (
                          </span>
                          <span
                            style={{
                              display: 'inline-block',
                              color: '#413043',
                              fontSize: '17px'
                            }}>
                            {inker.inker.ink}
                            <svg
                              style={{
                                width: '50px',
                                marginLeft: '-15px',
                                position: 'absolute',
                                zIndex: 0
                              }}
                              id='Layer_1'
                              data-name='Layer 1'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 2000 2000'>
                              <title>Ink</title>
                              <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                            </svg>
                          </span>
                          <span
                            style={{
                              color: '#da694b',
                              display: 'inline-block',
                              paddingLeft: '20px'
                            }}>
                            )
                          </span>
                        </div>
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <h4 id='no-following'>This poem does not have any inkers!</h4>
            )}
          </div>
        </div>
      );
    } else if (this.props.filterPoemsModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-filter'
          className='modal bottom-sheet'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Filter
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form
              onSubmit={event =>
                this.props.onSetFilter(
                  event,
                  $('input[name="group1"]:checked').val()
                )
              }>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='all'
                    defaultChecked={this.props.filter == 'all'}
                  />
                  <span style={{ fontSize: '17px' }}>All poems</span>
                </label>
              </p>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='classics'
                    defaultChecked={this.props.filter == 'classics'}
                  />
                  <span style={{ fontSize: '17px' }}>Classic poems only</span>
                </label>
              </p>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='original'
                    defaultChecked={this.props.filter == 'original'}
                  />
                  <span style={{ fontSize: '17px' }}>Community poems only</span>
                </label>
              </p>
              <button
                style={{ backgroundColor: '#da694b' }}
                id='filter-btn'
                className='btn z-depth-0 waves-effect modal-close'>
                Filter
              </button>
            </form>
          </div>
        </div>
      );
    } else if (this.props.filterPoetsModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-filter'
          className='modal bottom-sheet'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Filter
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form
              onSubmit={event =>
                this.props.onSetFilter(
                  event,
                  $('input[name="group1"]:checked').val()
                )
              }>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='all'
                    defaultChecked={this.props.filter == 'all'}
                  />
                  <span style={{ fontSize: '17px' }}>All poets</span>
                </label>
              </p>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='classics'
                    defaultChecked={this.props.filter == 'classics'}
                  />
                  <span style={{ fontSize: '17px' }}>Classic poets only</span>
                </label>
              </p>
              <p>
                <label className='filter-label'>
                  <input
                    name='group1'
                    type='radio'
                    value='original'
                    defaultChecked={this.props.filter == 'original'}
                  />
                  <span style={{ fontSize: '17px' }}>Community poets only</span>
                </label>
              </p>
              <button
                style={{ backgroundColor: '#da694b' }}
                id='filter-btn'
                className='btn z-depth-0 waves-effect modal-close'>
                Filter
              </button>
            </form>
          </div>
        </div>
      );
    } else if (this.props.editPage != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-publish-poem'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Publish poem
              <a
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'
                data-position='bottom'
                data-tooltip='Close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <div
              ref={chips => {
                this.chips = chips;
              }}
              id='tags'
              className='chips'></div>
            <form
              id='publish-poem-form'
              onSubmit={event => this.props.onSubmit(event)}>
              {!this.props.isPublishing && (
                <button
                  id='publish-poem-btn'
                  disabled={this.props.isInvalid}
                  className='btn z-depth-0 waves-effect modal-close'>
                  Publish
                </button>
              )}
              {this.props.isPublishing && (
                <button
                  id='publish-poem-btn'
                  disabled={this.props.isInvalid}
                  className='btn z-depth-0 waves-effect'>
                  Publishing...
                </button>
              )}
            </form>
          </div>
        </div>
      );
    } else if (this.props.commentModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-post-comment'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Share your honest comments
              <a
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'
                data-position='bottom'
                data-tooltip='Close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form
              id='create-poem-form'
              onSubmit={event =>
                this.props.onSubmit(event, this.props.authUser)
              }>
              <textarea
                id='comment'
                placeholder='Write your feeback here'
                className='materialize-textarea'
                onChange={this.props.onChange}></textarea>
              {!this.props.postingComment && (
                <button
                  disabled={this.props.isInvalid}
                  id='post-comment-btn'
                  className='btn z-depth-0 waves-effect'>
                  Post
                </button>
              )}
              {this.props.postingComment && (
                <button
                  disabled={true}
                  id='post-comment-btn'
                  className='btn z-depth-0 waves-effect'>
                  Posting...
                </button>
              )}
            </form>
          </div>
        </div>
      );
    } else if (this.props.showPoemInkers != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-inkers'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Inkers&nbsp;
              <span
                style={{
                  fontSize: '20px',
                  color: '#413043'
                }}>{`(${this.props.poem.ink})`}</span>
              <a
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'
                data-position='bottom'
                data-tooltip='Close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            {this.props.inkers == null ||
              (this.props.inkers.length < 1 && (
                <h4 id='no-following'>This poem does not have any inkers!</h4>
              ))}
            {this.props.inkers != null &&
              this.props.inkers.map((inker, index) => (
                <div key={index}>
                  <a
                    style={{ fontSize: '17px' }}
                    href={`/poets/@${inker.inker.penName}`}
                    className='inker'>
                    {inker.inker.penName}
                    <span>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {inker.inker.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                </div>
              ))}
          </div>
        </div>
      );
    } else if (this.props.modalFollowing != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-following'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Following&nbsp;
              <span
                style={{
                  fontSize: '20px',
                  color: '#413043'
                }}>{`(${this.props.poet.totalFollowing})`}</span>
              <a
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'
                data-position='bottom'
                data-tooltip='Close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            {this.props.following == null && (
              <h4 id='no-following'>This poet is not following anybody!</h4>
            )}
            {this.props.following != null &&
              this.props.following.length < 1 && (
                <h4 id='no-following'>This poet is not following anybody!</h4>
              )}
            {this.props.following != null &&
              this.props.following.map((following, index) => (
                <div key={index}>
                  <a
                    style={{ fontSize: '17px' }}
                    href={`/poets/@${following.penName}`}
                    className='poet-browse-name'>
                    {following.penName}
                    <span style={{ fontWeight: 'bold' }}>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {following.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                </div>
              ))}
          </div>
        </div>
      );
    } else if (this.props.modalFollowers != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-followers'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Followers&nbsp;
              <span
                style={{
                  fontSize: '20px',
                  color: '#413043'
                }}>{`(${this.props.poet.totalFollowers})`}</span>
              <a
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'
                data-position='bottom'
                data-tooltip='Close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            {this.props.followers == null && (
              <h4 id='no-following'>This poet has no followers!</h4>
            )}
            {this.props.followers != null &&
              this.props.followers.length < 1 && (
                <h4 id='no-following'>This poet has no followers!</h4>
              )}
            {this.props.followers != null &&
              this.props.followers.map((follower, index) => (
                <div key={index}>
                  <a
                    style={{ fontSize: '17px' }}
                    href={`/poets/@${follower.penName}`}
                    className='poet-browse-name'>
                    {follower.penName}
                    <span style={{ fontWeight: 'bold' }}>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {follower.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                </div>
              ))}
          </div>
        </div>
      );
    } else if (this.props.filterContestModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-filter'
          className='modal bottom-sheet'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Filter
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form>
              <p>
                <label className='filter-label'>
                  <input name='group1' type='radio' value='ending' />
                  <span style={{ fontSize: '17px' }}>Ending soon</span>
                </label>
              </p>
              <p>
                <label className='filter-label'>
                  <input name='group1' type='radio' value='starting' />
                  <span style={{ fontSize: '17px' }}>Largest ink pool</span>
                </label>
              </p>
              <button
                style={{ backgroundColor: '#da694b' }}
                id='filter-btn'
                className='btn z-depth-0 waves-effect modal-close'>
                Filter
              </button>
            </form>
          </div>
        </div>
      );
    } else if (this.props.createContestModal != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-create-contest'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Create contest
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close tooltipped'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form onSubmit={event => this.props.onSubmit(event)}>
              <div className='input-field'>
                <label htmlFor='contest-title'>Title</label>
                <input type='text' id='contest-title' />
              </div>
              <div className='input-field'>
                <textarea
                  id='prompt'
                  placeholder='Prompt'
                  className='materialize-textarea'></textarea>
              </div>
              <div className='file-field input-field'>
                <div
                  style={{
                    backgroundColor: '#da694b',
                    width: '50px',
                    paddingLeft: '15px'
                  }}
                  className='btn waves-effect z-depth-0'>
                  <i style={{ color: 'white' }} className='material-icons'>
                    file_upload
                  </i>
                  <input type='file'></input>
                </div>
                <div className='file-path-wrapper'>
                  <input
                    className='file-path'
                    type='text'
                    placeholder='Optional image'></input>
                </div>
              </div>
              <div className='input-field'>
                <input
                  placeholder='End date'
                  id='end-date'
                  className='datepicker'></input>
              </div>
              <button
                style={{ backgroundColor: '#da694b' }}
                id='create-contest-btn'
                className='btn z-depth-0 waves-effect'>
                Create
              </button>
            </form>
          </div>
        </div>
      );
    } else if (this.props.signUp != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-signup'
          className='modal'>
          <div className='modal-content'>
            <h4 id='signup-header'>
              Sign Up
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form onSubmit={this.props.onSubmit}>
              <div className='input-field'>
                <label htmlFor='email'>Email</label>
                <input onChange={this.props.onChange} type='text' id='email' />
              </div>
              <div className='input-field'>
                <label htmlFor='penName'>Pen Name</label>
                <input
                  onChange={this.props.onChange}
                  type='text'
                  id='penName'
                />
              </div>
              <div className='input-field'>
                <label htmlFor='password'>Password</label>
                <input
                  onChange={this.props.onChange}
                  type='password'
                  id='password'
                />
              </div>
              <a style={{ fontSize: '17px' }} href='#!' id='switch-to-login'>
                Already have an account?
                <br />
                <br />
              </a>
              <p style={{ marginTop: '-20px', fontSize: '17px' }}>
                By signing up you agree to our{' '}
                <a style={{ color: '#da694b' }} target='_blank' href='/tos'>
                  tos
                </a>{' '}
                and{' '}
                <a
                  target='_blank'
                  style={{ color: '#da694b', fontSize: '17px' }}
                  href='/privacy'>
                  privacy policy
                </a>
              </p>
              {this.props.loading ? (
                <button
                  disabled={this.props.isInvalid}
                  id='signup-btn'
                  className='btn z-depth-0 waves-effect'>
                  Signing up...
                </button>
              ) : (
                <button
                  disabled={this.props.isInvalid}
                  id='signup-btn'
                  className='btn z-depth-0 waves-effect'>
                  Sign Up
                </button>
              )}
            </form>
          </div>
        </div>
      );
    } else if (this.props.login != undefined) {
      return (
        <div
          ref={Modal => {
            this.Modal = Modal;
          }}
          id='modal-login'
          className='modal'>
          <div className='modal-content'>
            <h4 id='login-header'>
              Login
              <a
                href='#!'
                id='modal-close'
                className='modal-close btn btn-floating waves-effect btn-flat close'>
                <i className='small material-icons'>close</i>
              </a>
            </h4>
            <form onSubmit={this.props.onSubmit}>
              <div className='input-field'>
                <label htmlFor='email'>Email</label>
                <input onChange={this.props.onChange} type='text' id='email' />
              </div>
              <div className='input-field'>
                <label htmlFor='password'>Password</label>
                <input
                  onChange={this.props.onChange}
                  type='password'
                  id='password'
                />
              </div>
              <a style={{ fontSize: '17px' }} href='#!' id='switch-to-signup'>
                Don't have an account?
                <br />
              </a>
              <a
                style={{ fontSize: '17px' }}
                href='#!'
                id='switch-to-forgot-password'>
                Forgot Password?
                <br />
                <br />
              </a>
              {this.props.loading ? (
                <button
                  disabled={this.props.isInvalid}
                  id='login-btn'
                  className='btn z-depth-0 waves-effect'>
                  Logging in...
                </button>
              ) : (
                <button
                  disabled={this.props.isInvalid}
                  id='login-btn'
                  className='btn z-depth-0 waves-effect'>
                  Login
                </button>
              )}
            </form>
          </div>
        </div>
      );
    }
  }
}

export default Modal;
