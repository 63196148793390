import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import moment from 'moment';
import $ from 'jquery';
import Badwords from 'bad-words';

import Preloader from '../partials/Preloader';
import Modal from '../partials/Modal';

import TheEnd from '../partials/TheEnd';

import { withFirebase } from '../config';
import { AuthUserContext, withAuthorization } from '../session';

class Bookmarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingMore: false,
      bookmarks: [],
      limit: 10,
      isInking: false
    };
  }
  componentDidMount() {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
    document.title = 'Bookmarks' + ' - Poetryphile';
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        let loadingMore = false;
        this.onListenForBookmarks(loadingMore);
      }
    });
  }
  componentDidUpdate() {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
    var elems = document.querySelectorAll('.modal');
    M.Modal.init(elems);
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#scroll').fadeIn();
      } else {
        $('#scroll').fadeOut();
      }
    });
    $('#scroll').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 600);
      return false;
    });
  }
  async onGetBookmarks(user, snapshot, loadingMore) {
    const parent = this;
    let bookmarks = [];
    var currentUser = await this.props.firebase
      .user(this.props.firebase.auth.currentUser.uid)
      .get();
    for (var i in snapshot.docs) {
      const doc = snapshot.docs[i].data();
      let poem = await this.props.firebase.poem(doc.poemId).get();
      let poemData = poem.data();
      let poet = await this.props.firebase.user(poem.data().poetId).get(); // Get poem poet
      if (currentUser.data().hideExplicit == true) {
        var filter = new Badwords();
        poemData.text = filter.clean(poemData.text);
      }
      if (poem.data().inkers.length < 1) {
        await bookmarks.push({
          poem: poemData,
          poet: poet.data(),
          inkers: [],
          uid: snapshot.docs[i].id,
          ...doc
        });
      } else {
        let inkers = [];
        for (let i in poem.data().inkers) {
          const inkerId = await poem.data().inkers[i];
          let inker = await this.props.firebase.user(inkerId).get();
          await inkers.push({ inker: inker.data(), uid: inkerId });
        }
        await bookmarks.push({
          ink: poem.data().ink,
          poem: poemData,
          poet: poet.data(),
          inkers: inkers,
          uid: snapshot.docs[i].id,
          ...doc
        });
      }
      if (bookmarks.length == snapshot.size) {
        if (loadingMore == false) {
          parent.setState({
            bookmarks: bookmarks,
            loading: false
          });
        } else {
          parent.setState({
            bookmarks: bookmarks,
            loadingMore: false
          });
        }
      }
    }
    await this.props.firebase.db
      .collection('bookmarks')
      .where('bookmarkerId', '==', user.id)
      .get()
      .then(function (querySnapshot) {
        parent.setState({
          totalBookmarks: querySnapshot.size
        });
      });
  }
  onListenForBookmarks = loadingMore => {
    this.props.firebase
      .user(this.props.firebase.auth.currentUser.uid)
      .get()
      .then(user => {
        this.props.firebase.db
          .collection('bookmarks')
          .where('bookmarkerId', '==', user.id)
          .orderBy('bookmarkedAt', 'desc')
          .limit(this.state.limit)
          .get()
          .then(snapshot => {
            if (snapshot.size) {
              this.onGetBookmarks(user, snapshot, loadingMore);
            } else {
              if (loadingMore == false) {
                this.setState({
                  bookmarks: null,
                  totalBookmarks: 0,
                  loading: false
                });
              } else {
                this.setState({
                  bookmarks: null,
                  totalBookmarks: 0,
                  loadingMore: false
                });
              }
            }
          });
      });
  };
  loadMoreBookmarks = () => {
    this.setState(
      state => ({ limit: state.limit + 5, loadingMore: true }),
      this.addBookmarks
    );
  };
  addBookmarks = () => {
    let loadingMore = true;
    this.onListenForBookmarks(loadingMore);
  };
  async updatePoem(poem, id) {
    try {
      let poemDoc = await this.props.firebase.poem(id).get(); // Query poem clicked on

      let newInkers = await poemDoc.data().inkers.slice(); // Make copy of current inkers

      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == false // Only increment ink if inker has not already inked
      ) {
        newInkers.push(this.props.firebase.auth.currentUser.uid); // Add new inker to inkers array
        await this.props.firebase.poem(id).update({
          inkers: newInkers,
          ink: this.props.firebase.fieldValue.increment(1)
        });
      }
    } catch (err) {
      console.log('Error inking');
      this.setState({ isInking: false });
    }
  }

  async addInk(poem, id) {
    try {
      let author = await poem.poetRef.get();
      await this.props.firebase.inks().add({
        // Add ink document to represent given ink
        inkerRef: this.props.firebase.db
          .collection('users')
          .doc(this.props.firebase.auth.currentUser.uid), // Reference to inker
        poemRef: this.props.firebase.db.collection('poems').doc(id), // Reference to poem
        createdAt: this.props.firebase.fieldValue.serverTimestamp(), // Ink action timestamp
        inkerId: this.props.firebase.auth.currentUser.uid, // id of inker
        poemId: id, // id of poem
        authorId: author.id // id of author
      });
    } catch (err) {
      this.setState({ isInking: false });

      // If error, remove inker id from inkers array that was added in updatePoem function
      let poemDoc = await this.props.firebase.poem(id).get(); // Query peom being given ink

      let newInkers = await poemDoc.data().inkers.slice(); // Get copy of inkers
      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == true // If inkerId was successfully added to inker array
      ) {
        let filteredInkers = newInkers.filter(
          id => id !== this.props.firebase.auth.currentUser.uid // remove it
        );
        await this.props.firebase.poem(id).update({
          inkers: filteredInkers,
          ink: this.props.firebase.fieldValue.increment(-1)
        });
      }

      this.setState({ isInking: false });
    }

    try {
      let author = await poem.poetRef.get(); // Query author of poem being given ink
      await this.props.firebase.user(author.id).update({
        ink: this.props.firebase.fieldValue.increment(1) // Increment their ink
      });
      this.setState({ isInking: false });
    } catch (err) {
      // If error, remove inker id from inkers array that was added in updatePoem function

      this.setState({ isInking: false });

      let poemDoc = await this.props.firebase.poem(id).get(); // Query poem being given ink

      let newInkers = await poemDoc.data().inkers.slice(); // Get copy of inkers
      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == true // If inkerId was successfully added to inker array
      ) {
        let filteredInkers = newInkers.filter(
          id => id !== this.props.firebase.auth.currentUser.uid // Filter inkerId out of inker array
        );
        await this.props.firebase.poem(id).update({
          inkers: filteredInkers,
          ink: this.props.firebase.fieldValue.increment(-1) // Decrement the ink of the poem because ink action failed
        });
      }
      await this.props.firebase
        .inks()
        .where('inkerId', '==', this.props.firebase.auth.currentUser.uid)
        .where('poemId', '==', id)
        .onSnapshot(async snapshot => {
          if (snapshot.size) {
            for (var i in snapshot.docs) {
              const doc = snapshot.docs[i];
              await doc.ref.delete(); // Delete the ink document that was created because ink action failed
            }
          }
        });
    }
  }

  onInk = async (event, poem, id) => {
    try {
      event.preventDefault();

      this.setState({ isInking: true });

      await this.updatePoem(poem, id);

      await this.addInk(poem, id);

      this.setState({ isInking: false });

      let loadingMore = false;
      this.onListenForBookmarks(loadingMore);
    } catch (err) {
      console.log('Error inking ' + err);
      this.setState({ isInking: false });
    } finally {
      console.log('Finished giving ink');
    }
  };
  render() {
    const {
      loading,
      bookmarks,
      totalBookmarks,
      limit,
      loadingMore,
      isInking
    } = this.state;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loading == true ? (
                <Preloader />
              ) : (
                <div>
                  <ul
                    id='poems'
                    style={{ marginTop: '50px' }}
                    className='collection with-header container'>
                    <li
                      style={{ height: '90px' }}
                      className='collection-header'>
                      <p
                        id='my-poems'
                        style={{ marginTop: '13px' }}
                        className='futura'>
                        Bookmarks
                      </p>
                    </li>
                    <div>
                      {totalBookmarks < 1 || bookmarks == null ? (
                        <li id='no-public' className='collection-item'>
                          <center>
                            <svg
                              style={{ width: '200px', marginTop: '-10px' }}
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 2000 2000'>
                              <title>Poetryphile Saved</title>
                              <g id='Layer_3' data-name='Layer 3'>
                                <path
                                  d='M487.78,402.41V658.52H402.41v683h85.37v256.11H1597.59V402.41Zm0,341.48H964.71L768.93,939.68l-25,24.94v.09L708.51,1000l35.38,35.29v.09l25,24.95,195.78,195.78H487.78Zm1024.44,768.33H573.15V1341.48h512.22v-85.37L829.26,1000l256.11-256.11V658.52H573.15V487.78h939.07Z'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='768.93 1060.33 964.71 1256.11 487.78 1256.11 487.78 743.89 964.71 743.89 768.93 939.68 743.89 964.62 743.89 964.71 708.51 1000 743.89 1035.29 743.89 1035.38 768.93 1060.33'
                                  style={{ fill: '#da694b' }}
                                />
                              </g>
                            </svg>
                            <div
                              id='scroll'
                              style={{ top: '25px' }}
                              className='fixed-action-btn horizontal'>
                              <a
                                style={{ backgroundColor: '#da694b' }}
                                className='btn-floating btn-small'>
                                <i className='large material-icons'>
                                  arrow_drop_up
                                </i>
                              </a>
                            </div>
                            <h4 id='no-bookmarked-poems' className='futura'>
                              You do not have any bookmarked poems!
                            </h4>
                          </center>
                        </li>
                      ) : (
                        <BookmarkList
                          bookmarks={this.state.bookmarks}
                          limit={limit}
                          loadMoreBookmarks={this.loadMoreBookmarks}
                          loadingMore={loadingMore}
                          totalBookmarks={totalBookmarks}
                          authUser={authUser}
                          isInking={isInking}
                          onInk={this.onInk}
                        />
                      )}
                    </div>
                  </ul>
                  <br />
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const BookmarkList = ({
  bookmarks,
  limit,
  loadMoreBookmarks,
  loadingMore,
  totalBookmarks,
  authUser,
  isInking,
  onInk
}) => (
  <ul>
    {bookmarks &&
      bookmarks.map(poem => (
        <li key={poem.uid} className='collection-item'>
          <Modal poem={poem} inkerModal={true} />
          <div
            className={[
              poem.poem.format == 'format_align_left' && 'format-left',
              poem.poem.format == 'format_align_center' && 'format-center',
              poem.poem.format == 'format_align_right' && 'format-right'
            ]
              .filter(e => e)
              .join(' ')}>
            <div style={{ fontSize: '17px', paddingTop: '20px' }}>
              By&nbsp;
              <a
                href={`/poets/@${poem.poet.penName}`}
                className='poet-browse-name'>
                {poem.poet.penName}
                <span style={{ fontWeight: 'bold' }}>
                  <div className='ink-line ink-score'>
                    <span
                      style={{
                        color: '#da694b',
                        display: 'inline-block',
                        paddingRight: '3px',
                        paddingLeft: '5px'
                      }}>
                      (
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        color: '#413043',
                        fontSize: '17px'
                      }}>
                      {poem.poet.ink}
                      <svg
                        style={{
                          width: '50px',
                          marginLeft: '-15px',
                          position: 'absolute',
                          zIndex: 0
                        }}
                        id='Layer_1'
                        data-name='Layer 1'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 2000 2000'>
                        <title>Ink</title>
                        <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#da694b',
                        display: 'inline-block',
                        paddingLeft: '20px'
                      }}>
                      )
                    </span>
                  </div>
                </span>
              </a>
              <br />
              {poem.poem.isClassic == false ? (
                <p
                  className={[
                    'created-at',
                    poem.poem.format == 'format_align_center' &&
                      'created-at-center'
                  ]
                    .filter(e => e)
                    .join(' ')}>
                  {moment(poem.poem.datePublic.toDate()).fromNow()}
                </p>
              ) : (
                <p
                  className={[
                    'created-at',
                    poem.poem.format == 'format_align_center' &&
                      'created-at-center'
                  ]
                    .filter(e => e)
                    .join(' ')}>
                  {poem.poem.classicDate}
                </p>
              )}
            </div>
            {poem.poem.title.replace(/\s/g, '') == '' && (
              <a
                href={`/poems/${poem.uid}/edit`}
                className='poem-title-new futura'>
                Untitled Poem
              </a>
            )}
            <a
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
              href={`/poems/${poem.uid}`}
              className='poem-title-new'>
              {poem.poem.title}
            </a>
            <br />
            <div
              className='poem-text'
              id={poem.uid}
              style={{
                marginTop: '5px',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap'
              }}>
              {poem.poem.text.substring(0, 150)}
            </div>
            {poem.poem.text.length > 150 && (
              <div style={{ paddingTop: '5px' }}>
                <a
                  href='#!'
                  id={`${poem.uid}read-more`}
                  onClick={() => {
                    $(`#${poem.uid}`).html(poem.poem.text);
                    $(`#${poem.uid}read-more`).css('display', 'none');
                  }}
                  className='read-more'>
                  Read More
                </a>
              </div>
            )}
            <div>
              <a
                href='#!'
                data-target={`${poem.uid}modal-inkers`}
                style={{ fontSize: '17px', marginLeft: '40px' }}
                className={[
                  poem.poem.format == 'format_align_center' &&
                    'format-center-num',
                  poem.poem.format == 'format_align_left' && 'format-left-num',
                  poem.poem.format == 'format_align_right' &&
                    'format-right-num',
                  'modal-trigger',
                  authUser != undefined &&
                    poem.inkers.some(e => e.uid == authUser.uid) &&
                    'orange-ink-count'
                ]
                  .filter(e => e)
                  .join(' ')}>
                {poem.poem.ink} ink
              </a>
              {authUser != null ? (
                <div>
                  {poem.inkers.some(e => e.uid == authUser.uid) ? (
                    <svg
                      className={[
                        poem.poem.format == 'format_align_center' &&
                          'format-center-ink',
                        poem.poem.format == 'format_align_left' &&
                          'format-left-ink',
                        poem.poem.format == 'format_align_right' &&
                          'format-right-ink',
                        'ink-upvoted'
                      ]
                        .filter(e => e)
                        .join(' ')}
                      id='Layer_1'
                      data-name='Layer 1'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 2000 2000'>
                      <title>Gave Ink</title>
                      <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                    </svg>
                  ) : (
                    <div>
                      {isInking != true ? (
                        <svg
                          onClick={event => onInk(event, poem.poem)}
                          className={[
                            poem.poem.format == 'format_align_center' &&
                              'format-center-ink',
                            poem.poem.format == 'format_align_left' &&
                              'format-left-ink',
                            poem.poem.format == 'format_align_right' &&
                              'format-right-ink',
                            'ink-hover'
                          ]
                            .filter(e => e)
                            .join(' ')}
                          id='Layer_1'
                          data-name='Layer 1'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 2000 2000'>
                          <title>Give Ink</title>
                          <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                        </svg>
                      ) : (
                        <svg
                          className={[
                            poem.poem.format == 'format_align_center' &&
                              'format-center-ink',
                            poem.poem.format == 'format_align_left' &&
                              'format-left-ink',
                            poem.poem.format == 'format_align_right' &&
                              'format-right-ink',
                            'ink-hover'
                          ]
                            .filter(e => e)
                            .join(' ')}
                          id='Layer_1'
                          data-name='Layer 1'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 2000 2000'>
                          <title>Giving Ink</title>
                          <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                        </svg>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <svg
                    className={[
                      poem.poem.format == 'format_align_center' &&
                        'format-center-ink',
                      poem.poem.format == 'format_align_left' &&
                        'format-left-ink',
                      poem.poem.format == 'format_align_right' &&
                        'format-right-ink'
                    ]
                      .filter(e => e)
                      .join(' ')}
                    id='Layer_1'
                    data-name='Layer 1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 2000 2000'>
                    <title>Ink</title>
                    <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                  </svg>
                </div>
              )}
            </div>
            {poem.isClassic && (
              <div style={{ marginTop: '20px', marginBottom: '-10px' }}>
                <div className='chip'>Classic</div>
              </div>
            )}
            <p style={{ visibility: 'hidden', marginBottom: '25px' }}></p>
          </div>
        </li>
      ))}
    {limit >= totalBookmarks && loadingMore == false && <TheEnd />}
    {bookmarks && totalBookmarks > limit && loadingMore == false && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='load-more' onClick={loadMoreBookmarks}>
            Load More
          </a>
        </center>
      </li>
    )}
    {bookmarks && totalBookmarks + 5 > limit && loadingMore == true && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='loading-more'>
            Loading more...
          </a>
        </center>
      </li>
    )}
  </ul>
);

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(Bookmarks);
