import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import moment from 'moment';
import $ from 'jquery';

import Preloader from '../partials/Preloader';
import TheEnd from '../partials/TheEnd';

import { withFirebase } from '../config';
import { AuthUserContext, withAuthorization } from '../session';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      notifications: [],
      limit: 10,
      loadingMore: false,
      totalNotifications: 0
    };
  }
  componentDidMount() {
    document.title = 'Notifications' + ' - Poetryphile';
    this.onListenForNotifications();
  }
  componentDidUpdate() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#scroll').fadeIn();
      } else {
        $('#scroll').fadeOut();
      }
    });
    $('#scroll').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 600);
      return false;
    });
  }
  async onGetNotifications(snapshot) {
    let notifications = [];
    const parent = this;

    for (var i in snapshot.docs) {
      const doc = await snapshot.docs[i].data();
      let inker, commenter, follower, poem;

      if (doc.content == 'comment') {
        commenter = await this.props.firebase
          .user(doc.comment.commenterId)
          .get();

        poem = await this.props.firebase.poem(doc.comment.poemId).get();

        notifications.push({
          ...doc,
          commenter: commenter.data(),
          poem: poem.data(),
          uid: snapshot.docs[i].id
        });
      } else if (doc.content == 'inked') {
        inker = await this.props.firebase.user(doc.ink.inkerId).get();

        poem = await this.props.firebase.poem(doc.ink.poemId).get();

        notifications.push({
          ...doc,
          inker: inker.data(),
          poem: poem.data(),
          uid: snapshot.docs[i].id
        });
      } else {
        follower = await this.props.firebase.user(doc.followerId).get();

        poem = await this.props.firebase.poem(doc.poemId).get();

        notifications.push({
          ...doc,
          follower: follower.data(),
          poem: poem.data(),
          uid: snapshot.docs[i].id
        });
      }

      if (notifications.length == snapshot.size) {
        parent.setState({
          notifications: notifications
        });
      }
    }
    await parent.props.firebase
      .notifications()
      .where('poetId', '==', parent.props.firebase.auth.currentUser.uid)
      .get()
      .then(function (querySnapshot) {
        parent.setState({
          totalNotifications: querySnapshot.size,
          loading: false
        });
      });
  }
  async onGetAddNotifications(snapshot) {
    let notifications = [];
    const parent = this;

    for (var i in snapshot.docs) {
      const doc = await snapshot.docs[i].data();

      let inker = await this.props.firebase.user(doc.ink.inkerId).get();

      let poem = await this.props.firebase.poem(doc.ink.poemId).get();

      notifications.push({
        ...doc,
        inker: inker.data(),
        poem: poem.data(),
        uid: snapshot.docs[i].id
      });

      if (notifications.length == snapshot.size) {
        parent.setState({
          notifications: notifications
        });
      }
    }
    await parent.props.firebase
      .notifications()
      .where('poetId', '==', parent.props.firebase.auth.currentUser.uid)
      .get()
      .then(function (querySnapshot) {
        parent.setState({
          totalNotifications: querySnapshot.size,
          loadingMore: false
        });
      });
  }
  onListenForNotifications = () => {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.props.firebase
          .notifications()
          .orderBy('createdAt', 'desc')
          .limit(this.state.limit)
          .where('poetId', '==', this.props.firebase.auth.currentUser.uid)
          .onSnapshot(snapshot => {
            if (snapshot.size) {
              this.onGetNotifications(snapshot);
            } else {
              this.setState({
                notifications: null,
                totalNotifications: 0,
                loading: false
              });
            }
          });
      }
    });
  };
  loadMoreNotifications = () => {
    this.setState(
      state => ({ limit: state.limit + 5, loadingMore: true }),
      this.addNotifications
    );
  };
  addNotifications = () => {
    this.props.firebase
      .notifications()
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .where('poetId', '==', this.props.firebase.auth.currentUser.uid)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          this.onGetAddNotifications(snapshot);
        } else {
          this.setState({ notifications: null, loadingMore: false });
        }
      });
  };
  render() {
    const {
      loading,
      notifications,
      limit,
      loadingMore,
      totalNotifications
    } = this.state;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loading == true ? (
                <Preloader />
              ) : (
                <div>
                  <ul
                    id='poems'
                    style={{ marginTop: '50px' }}
                    className='collection with-header container'>
                    <li
                      style={{ height: '90px' }}
                      className='collection-header'>
                      <p
                        style={{ marginTop: '13px' }}
                        id='my-poems'
                        className='futura'>
                        Notifications
                      </p>
                    </li>
                    <div>
                      {totalNotifications < 1 || notifications == null ? (
                        <li id='no-public' className='collection-item'>
                          <center>
                            <svg
                              style={{ width: '200px', marginTop: '-5px' }}
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 2000 2000'>
                              <title>Poetryphile Icon Announcements</title>
                              <g id='Layer_2' data-name='Layer 2'>
                                <path
                                  d='M727.37,273v908.77h545.26V273Zm454.38,817.9H818.25v-727h363.5Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M727.37,1272.63V1727h545.26V1272.63Zm454.38,363.51H818.25V1363.51h363.5Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M545.61,1000a90.89,90.89,0,0,0,90.88,90.88v90.87c-100.37,0-181.75-81.38-181.75-181.75s81.38-181.75,181.75-181.75v90.87A90.89,90.89,0,0,0,545.61,1000Z'
                                  style={{ fill: '#da694b' }}
                                />
                                <path
                                  d='M363.86,1000c0,150.56,122.07,272.63,272.63,272.63v90.88C435.75,1363.51,273,1200.74,273,1000S435.75,636.49,636.49,636.49v90.88C485.93,727.37,363.86,849.44,363.86,1000Z'
                                  style={{ fill: '#da694b' }}
                                />
                                <path
                                  d='M1454.39,1000a90.89,90.89,0,0,1-90.88,90.88v90.87c100.37,0,181.75-81.38,181.75-181.75s-81.38-181.75-181.75-181.75v90.87A90.89,90.89,0,0,1,1454.39,1000Z'
                                  style={{ fill: '#da694b' }}
                                />
                                <path
                                  d='M1636.14,1000c0,150.56-122.07,272.63-272.63,272.63v90.88c200.74,0,363.51-162.77,363.51-363.51s-162.77-363.51-363.51-363.51v90.88C1514.07,727.37,1636.14,849.44,1636.14,1000Z'
                                  style={{ fill: '#da694b' }}
                                />
                              </g>
                            </svg>
                            <div
                              id='scroll'
                              style={{ top: '25px' }}
                              className='fixed-action-btn horizontal'>
                              <a
                                style={{ backgroundColor: '#da694b' }}
                                className='btn-floating btn-small'>
                                <i className='large material-icons'>
                                  arrow_drop_up
                                </i>
                              </a>
                            </div>
                            <h4 id='no-public-poems' className='futura'>
                              No Notifications!
                            </h4>
                          </center>
                        </li>
                      ) : (
                        <NotificationsList
                          notifications={notifications}
                          limit={limit}
                          loadMoreNotifications={this.loadMoreNotifications}
                          loadingMore={loadingMore}
                          totalNotifications={totalNotifications}
                        />
                      )}
                    </div>
                  </ul>
                  <br />
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const NotificationsList = ({
  notifications,
  limit,
  loadMoreNotifications,
  loadingMore,
  totalNotifications
}) => (
  <ul>
    {notifications &&
      notifications.map(notification => (
        <li
          style={{ fontSize: '17px' }}
          key={notification.uid}
          className='collection-item notification avatar'>
          <div style={{ marginTop: '10px' }}>
            {notification.content == 'inked' && (
              <div>
                <img
                  alt='Poet avatar'
                  style={{ marginTop: '2px' }}
                  id='poet-profile-img'
                  className='circle'
                  src={notification.inker.avatar}></img>
                <div
                  style={{
                    marginTop: '-19px',
                    marginBottom: '0px',
                    fontSize: '17px'
                  }}>
                  <a
                    href={`/poets/@${notification.inker.penName}`}
                    className='poet-browse-name'>
                    {notification.inker.penName}
                    <span style={{ fontWeight: 'bold' }}>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {notification.inker.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                  &nbsp;gave you ink on{' '}
                  {notification.poem != undefined ? (
                    <span>
                      <a
                        style={{ color: '#da694b' }}
                        className='notification-item'
                        href={`/poems/${notification.ink.poemId}`}>
                        {notification.poem.title}
                      </a>
                      <br />
                      <p style={{ marginTop: '5px' }} className='created-at'>
                        {moment(notification.ink.createdAt.toDate()).fromNow()}
                      </p>
                    </span>
                  ) : (
                    <span>
                      <a href='#!' className='notification-item deleted'>
                        [deleted poem]
                      </a>
                      <br />
                      <p style={{ marginTop: '5px' }} className='created-at'>
                        {moment(notification.ink.createdAt.toDate()).fromNow()}
                      </p>
                    </span>
                  )}
                </div>
              </div>
            )}
            {notification.content == 'comment' && (
              <div>
                <img
                  alt='Poet avatar'
                  style={{ marginTop: '2px' }}
                  id='poet-profile-img'
                  className='circle'
                  src={notification.commenter.avatar}></img>
                <div
                  style={{
                    marginTop: '-17px',
                    marginBottom: '0px',
                    fontSize: '17px'
                  }}>
                  <a
                    href={`/poets/@${notification.commenter.penName}`}
                    className='poet-browse-name'>
                    {notification.commenter.penName}
                    <span style={{ fontWeight: 'bold' }}>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {notification.commenter.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                  &nbsp;left a comment on{' '}
                  {notification.poem != undefined ? (
                    <span>
                      <a
                        style={{ color: '#da694b' }}
                        className='notification-item'
                        href={`/poems/${notification.comment.poemId}`}>
                        {notification.poem.title}
                      </a>
                      <br />
                      <p style={{ marginTop: '5px' }} className='created-at'>
                        {moment(
                          notification.comment.createdAt.toDate()
                        ).fromNow()}
                      </p>
                    </span>
                  ) : (
                    <span>
                      <a href='#!' className='notification-item deleted'>
                        [deleted poem]
                      </a>
                      <br />
                      <p style={{ marginTop: '5px' }} className='created-at'>
                        {moment(
                          notification.comment.createdAt.toDate()
                        ).fromNow()}
                      </p>
                    </span>
                  )}
                </div>
              </div>
            )}
            {notification.content == 'followed' && (
              <div>
                <img
                  alt='Poet avatar'
                  style={{ marginTop: '2px' }}
                  id='poet-profile-img'
                  className='circle'
                  src={notification.follower.avatar}></img>
                <div
                  style={{
                    marginTop: '-17px',
                    marginBottom: '0px',
                    fontSize: '17px'
                  }}>
                  <a
                    href={`/poets/@${notification.follower.penName}`}
                    className='poet-browse-name'>
                    {notification.follower.penName}
                    <span style={{ fontWeight: 'bold' }}>
                      <div className='ink-line ink-score'>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingRight: '3px',
                            paddingLeft: '5px'
                          }}>
                          (
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#413043',
                            fontSize: '17px'
                          }}>
                          {notification.follower.ink}
                          <svg
                            style={{
                              width: '50px',
                              marginLeft: '-15px',
                              position: 'absolute',
                              zIndex: 0
                            }}
                            id='Layer_1'
                            data-name='Layer 1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 2000 2000'>
                            <title>Ink</title>
                            <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                          </svg>
                        </span>
                        <span
                          style={{
                            color: '#da694b',
                            display: 'inline-block',
                            paddingLeft: '20px'
                          }}>
                          )
                        </span>
                      </div>
                    </span>
                  </a>
                  &nbsp;<span>started following you!</span>
                  <br />
                  <p style={{ marginTop: '5px' }} className='created-at'>
                    {moment(notification.createdAt.toDate()).fromNow()}
                  </p>
                </div>
              </div>
            )}
          </div>
        </li>
      ))}
    {limit >= totalNotifications && loadingMore == false && <TheEnd />}
    {notifications && totalNotifications > limit && loadingMore == false && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='load-more' onClick={loadMoreNotifications}>
            Load More
          </a>
        </center>
      </li>
    )}
    {notifications && totalNotifications + 5 > limit && loadingMore == true && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='loading-more'>
            Loading more...
          </a>
        </center>
      </li>
    )}
  </ul>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Notifications);
