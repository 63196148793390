import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import $ from 'jquery';

import FourOFour from '../partials/FourOFour';
import Modal from '../partials/Modal';
import Preloader from '../partials/Preloader';

import { withFirebase } from '../config';
import { AuthUserContext, withAuthorization } from '../session';

class EditPoem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: this.props.match.params.id,
      poem: {},
      title: '',
      text: '',
      timeoutId: '',
      saved: true,
      format: 'format_align_left',
      isPublishing: false
    };
  }
  componentDidMount() {
    document.title = 'Edit poem' + ' - Poetryphile';

    var elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, { hoverEnabled: false });

    this.onListenForPoem();
  }
  componentDidUpdate() {
    var elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, { hoverEnabled: false });

    M.Chips.init(this.chips, {
      data: this.state.tags,
      placeholder: 'Tags(optional)',
      secondaryPlaceholder: '+Add more',
      autocompleteOptions: {
        data: {
          love: null
        },
        limit: 2,
        minLength: 1
      }
    });
  }
  onListenForPoem = () => {
    this.props.firebase
      .poem(this.state.id)
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log(doc.data());
          this.setState({
            poem: doc.data(),
            title: doc.data().title,
            text: doc.data().text,
            loading: false,
            tags: doc.data().tags,
            format: doc.data().format
          });
          if (doc.data().title.replace(/\s/g, '') == '') {
            this.setState({ title: 'Untitled Poem' });
          }

          M.Chips.init(this.chips, {
            data: this.state.tags,
            placeholder: 'Tags(optional)',
            secondaryPlaceholder: '+Add more',
            autocompleteOptions: {
              data: {
                love: null
              },
              limit: 2,
              minLength: 1
            }
          });
          if (doc.data().format == 'format_align_left') {
            $('#text').css('textAlign', 'left');
          }
          if (doc.data().format == 'format_align_right') {
            $('#text').css('textAlign', 'right');
          }
          if (doc.data().format == 'format_align_center') {
            $('#text').css('textAlign', 'center');
          }
          $('#text').val(this.state.text);
          M.textareaAutoResize($('#text'));
        } else {
          this.setState({ poem: null, loading: false });
        }
      });
  };
  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
    this.setState({ saved: false });
    const parent = this;
    clearTimeout(this.state.timeoutId);
    this.state.timeoutId = setTimeout(
      function () {
        this.props.firebase
          .poem(parent.state.id)
          .update({
            title: this.state.title,
            text: this.state.text,
            editedAt: this.props.firebase.fieldValue.serverTimestamp()
          })
          .then(() => {
            $('#text').val(this.state.text);
            M.textareaAutoResize($('#text'));
          });
        parent.setState({ saved: true });
      }.bind(this),
      1000
    );
  };
  formatLeft = () => {
    this.setState({ format: 'format_align_left' });
    this.props.firebase.poem(this.state.id).update({
      format: 'format_align_left'
    });
    document.querySelector('textarea').classList.add('left-justify');
    document.querySelector('textarea').classList.remove('center-justify');
    document.querySelector('textarea').classList.remove('right-justify');
  };
  formatCenter = () => {
    this.setState({ format: 'format_align_center' });
    this.props.firebase.poem(this.state.id).update({
      format: 'format_align_center'
    });
    document.querySelector('textarea').classList.add('center-justify');
    document.querySelector('textarea').classList.remove('left-justify');
    document.querySelector('textarea').classList.remove('right-justify');
  };
  formatRight = () => {
    this.setState({ format: 'format_align_right' });
    this.props.firebase.poem(this.state.id).update({
      format: 'format_align_right'
    });
    document.querySelector('textarea').classList.add('right-justify');
    document.querySelector('textarea').classList.remove('left-justify');
    document.querySelector('textarea').classList.remove('center-justify');
  };
  onSubmit = event => {
    event.preventDefault();

    this.setState({ isPublishing: true });

    const tagsArray = [];
    M.Chips.getInstance($('.chips')).chipsData.forEach(function (tag) {
      tagsArray.push(tag.tag);
    });

    if (this.state.title.replace(/\s/g, '').length < 1) {
      M.toast({ html: 'Please add a title' });
      this.setState({ isPublishing: false });
    } else if (this.state.text.replace(/\s/g, '').length < 1) {
      M.toast({ html: 'Please add text to your poem' });
      this.setState({ isPublishing: false });
    } else {
      if (this.state.poem.type == 'draft') {
        this.props.firebase.db
          .collection('poems')
          .doc('collectionInfo')
          .update({
            totalPublic: this.props.firebase.fieldValue.increment(1),
            totalDrafts: this.props.firebase.fieldValue.increment(-1)
          });

        this.props.firebase.db
          .collection('users')
          .doc(this.state.poem.poetId)
          .update({
            totalPublic: this.props.firebase.fieldValue.increment(1),
            totalDrafts: this.props.firebase.fieldValue.increment(-1)
          });

        this.props.firebase
          .poem(this.state.id)
          .update({
            type: 'public',
            ink: 0,
            totalComments: 0,
            tags: M.Chips.getInstance($('.chips')).chipsData,
            datePublic: this.props.firebase.fieldValue.serverTimestamp(),
            tagsArray: tagsArray
          })
          .then(() => {
            M.toast({
              html: 'Congratulations! Your poem was published'
            });
            this.setState({
              isPublishing: false
            });
            this.onListenForPoem();
          })
          .catch(err => {
            console.log(err);
            this.setState({
              isPublishing: false
            });
            M.toast({
              html: 'Unable to publish poem. Please try again later.'
            });
          });
      } else {
        this.props.firebase
          .poem(this.state.id)
          .update({
            tags: M.Chips.getInstance($('.chips')).chipsData,
            tagsArray: tagsArray
          })
          .then(() => {
            M.toast({ html: 'Congratulations! Your poem was published' });
            this.setState({ isPublishing: false });
            this.onListenForPoem();
          })
          .catch(err => {
            console.log(err);
            this.setState({ isPublishing: false });
            M.toast({
              html: 'Unable to publish poem. Please try again later.'
            });
          });
      }
    }
  };
  render() {
    const { loading, poem, isPublishing } = this.state;
    const isInvalid = isPublishing == true;
    return (
      <div>
        {loading ? (
          <Preloader />
        ) : (
          <div>
            <AuthUserContext.Consumer>
              {authUser => (
                <div>
                  {poem == null ? (
                    <div>
                      <FourOFour />
                    </div>
                  ) : (
                    <div>
                      {poem.poet.uid == authUser.uid ? (
                        <div>
                          <ul
                            id='toolbar'
                            style={{ marginTop: '50px' }}
                            className='collection container'>
                            <div>
                              <span
                                className='right'
                                style={{ marginBottom: '10px' }}>
                                <a
                                  id='publish'
                                  className='btn btn-floating waves-effect btn-flat close modal-trigger'
                                  data-target='modal-publish-poem'>
                                  <i
                                    id='publish-icon'
                                    className='material-icons'>
                                    check
                                  </i>
                                </a>
                              </span>
                              <span
                                className='right'
                                style={{
                                  marginTop: '10px',
                                  marginRight: '15px'
                                }}>
                                <span className='badge-field'>{poem.type}</span>
                              </span>

                              {this.state.saved ? (
                                <span
                                  className='right'
                                  style={{
                                    marginBottom: '10px',
                                    marginRight: '26px',
                                    marginTop: '10px'
                                  }}>
                                  <a className='save-process'>Saved</a>
                                </span>
                              ) : (
                                <span
                                  className='right'
                                  style={{
                                    marginBottom: '10px',
                                    marginRight: '26px',
                                    marginTop: '10px'
                                  }}>
                                  <a className='save-process'>Saving...</a>
                                </span>
                              )}
                            </div>
                          </ul>
                          <ul
                            id='poem-edit'
                            className='collection with-header container'>
                            <li
                              style={{ borderBottom: 'none' }}
                              className='collection-header'>
                              <div>
                                <div className='input-field'>
                                  <input
                                    maxLength='60'
                                    type='text'
                                    id='title'
                                    placeholder='Title'
                                    onChange={this.onChange}
                                    value={this.state.title}
                                  />
                                </div>
                                <textarea
                                  style={{ fontFamily: 'Futura' }}
                                  id='text'
                                  placeholder='Write your poem here'
                                  className='materialize-textarea'
                                  onChange={this.onChange}
                                  value={this.state.text}></textarea>
                              </div>
                            </li>
                          </ul>
                          <br />
                          <br />
                          <div className='fixed-action-btn horizontal'>
                            <a
                              id='content-align'
                              className='btn-floating btn-large'>
                              <i className='large material-icons'>
                                {this.state.format}
                              </i>
                            </a>
                            <ul>
                              <li>
                                <a
                                  onClick={this.formatLeft}
                                  className='btn-floating format-option'>
                                  <i className='material-icons'>
                                    format_align_left
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={this.formatCenter}
                                  className='btn-floating format-option'>
                                  <i className='material-icons'>
                                    format_align_center
                                  </i>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={this.formatRight}
                                  className='btn-floating format-option'>
                                  <i className='material-icons'>
                                    format_align_right
                                  </i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <Modal
                            editPage={true}
                            tags={this.state.tags}
                            onSubmit={this.onSubmit}
                            isPublishing={isPublishing}
                            isInvalid={isInvalid}
                          />
                        </div>
                      ) : (
                        <div>
                          <div>
                            <center>
                              <svg
                                id='Layer_1'
                                style={{ width: '400px' }}
                                data-name='Layer 1'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 2000 2000'>
                                <title>Poetryphile Locked Poem</title>
                                <path
                                  d='M1325.05,1243.78a54.17,54.17,0,0,0,54.17-54.17V1086.5l-114.85,157.28Zm0-433.39h-13.36l67.53,92.52V864.56A54.18,54.18,0,0,0,1325.05,810.39ZM620.78,1189.61A54.17,54.17,0,0,0,675,1243.78h60.44L620.78,1087ZM675,810.39a54.18,54.18,0,0,0-54.17,54.17v38.17l67.48-92.34Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M1401.67,787.94a108.21,108.21,0,0,0-76.62-31.73c0-167.21-126.35-305-288.81-323-8-.9-16-1.5-24.08-1.8-4-.18-8.07-.24-12.16-.24s-8.37.06-12.52.3h-.06c-7.89.24-15.71.84-23.42,1.68C801.42,451,675,588.88,675,756.21A108.35,108.35,0,0,0,566.61,864.56v325A108.35,108.35,0,0,0,675,1298H775l198,270.87H1027L1224.82,1298h100.23a108.36,108.36,0,0,0,108.35-108.35v-325A108.21,108.21,0,0,0,1401.67,787.94Zm-22.45,76.62v38.35l-67.53-92.52h13.36A54.18,54.18,0,0,1,1379.22,864.56Zm-650.09-110v-.06c.78-119.55,79-220.73,187-255.88h.06A271.8,271.8,0,0,1,993,485.4c2.34,0,4.69-.06,7-.06s4.88.06,7.28.06A269.73,269.73,0,0,1,1084,498.65C1192,533.86,1270.15,635,1270.87,754.53l1.27,1.68H727.86Zm-108.35,110A54.18,54.18,0,0,1,675,810.39h13.31l-67.48,92.34ZM675,1243.78a54.17,54.17,0,0,1-54.17-54.17V1087l114.61,156.8Zm704.27-54.17a54.17,54.17,0,0,1-54.17,54.17h-60.68l114.85-157.28Zm.06-194.79-.06-.06-181.9,249L1157.71,1298l-130.62,178.89V1103.71a81.26,81.26,0,1,0-54.18,0v373.2l-130.8-179-39.61-54.18L620.78,995.12v-.54L755.43,810.39h489.14l134.65,184.25.06-.06.18.12Z'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='727.86 756.21 729.13 754.53 729.13 756.21 727.86 756.21'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='1272.14 756.21 1270.87 756.21 1270.87 754.53 1272.14 756.21'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M1379.28,994.58l-.06.06v.12l.06.06.18-.12Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M688.26,810.39l-67.48,92.34V864.56A54.18,54.18,0,0,1,675,810.39Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M735.39,1243.78H675a54.17,54.17,0,0,1-54.17-54.17V1087Z'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='729.13 754.53 729.13 756.21 727.86 756.21 729.13 754.53'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M1379.22,1086.5v103.11a54.17,54.17,0,0,1-54.17,54.17h-60.68Z'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='1379.46 994.7 1379.28 994.82 1379.22 994.76 1379.22 994.64 1379.28 994.58 1379.46 994.7'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M1379.22,864.56v38.35l-67.53-92.52h13.36A54.18,54.18,0,0,1,1379.22,864.56Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M620.78,1087v102.63A54.17,54.17,0,0,0,675,1243.78h60.44ZM675,810.39a54.18,54.18,0,0,0-54.17,54.17v38.17l67.48-92.34Zm650.1,0h-13.36l67.53,92.52V864.56A54.18,54.18,0,0,0,1325.05,810.39Zm-60.68,433.39h60.68a54.17,54.17,0,0,0,54.17-54.17V1086.5Z'
                                  style={{ fill: '#da694b' }}
                                />
                                <polygon
                                  points='1272.14 756.21 1270.87 756.21 1270.87 754.53 1272.14 756.21'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='1379.46 994.7 1379.28 994.82 1379.22 994.76 1379.22 994.64 1379.28 994.58 1379.46 994.7'
                                  style={{ fill: '#413043' }}
                                />
                              </svg>
                              <h4 id='no-login' className='futura'>
                                You do not have access!
                              </h4>
                            </center>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </AuthUserContext.Consumer>
          </div>
        )}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(EditPoem);
