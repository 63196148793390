import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import M from 'materialize-css';

const config = {
  apiKey: 'AIzaSyBCRErC0bXWlZvYtu9PIH7NkOIO1FDiL3E',
  authDomain: 'poetryphile-89b6b.firebaseapp.com',
  databaseURL: 'https://poetryphile-89b6b.firebaseio.com',
  projectId: 'poetryphile-89b6b',
  storageBucket: 'poetryphile-89b6b.appspot.com',
  messagingSenderId: '741632910176',
  appId: '1:741632910176:web:0199d55dd0c02b2666b405'
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () =>
    this.auth.signOut().then(() => {
      M.toast({ html: 'Logged out!' });
    });

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  poem = (uid) => this.db.doc(`poems/${uid}`);

  poems = () => this.db.collection('poems');

  comments = () => this.db.collection('comments');

  comment = (uid) => this.db.doc(`comments/${uid}`);

  inks = () => this.db.collection('inks');

  ink = (uid) => this.db.doc(`inks/${uid}`);

  notifications = () => this.db.collection('notifications');

  notification = (uid) => this.db.collection(`notifications/${uid}`);

  splotching = () => this.db.collection('splotching');

  splotch = (uid) => this.db.collection(`splotching/${uid}`);

  reports = () => this.db.collection('reports');

  report = (uid) => this.db.collection(`reports/${uid}`);

  bookmarks = () => this.db.collection('bookmarks');

  bookmark = (uid) => this.db.collection(`bookmarks/${uid}`);
}

export default Firebase;
