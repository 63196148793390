import React, { Component } from 'react';
import { compose } from 'recompose';
import M from 'materialize-css';
import moment from 'moment';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Badwords from 'bad-words';

import TheEnd from '../partials/TheEnd';
import Modal from '../partials/Modal';
import Preloader from '../partials/Preloader';

import { withFirebase } from '../config';
import { AuthUserContext } from '../session';
import FourOFour from '../partials/FourOFour';

class ShowPoem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPoem: true,
      loadingComments: true,
      id: this.props.match.params.id,
      poem: {},
      bookmarked: false,
      isBookmarking: false,
      poet: {},
      postingComment: false,
      comment: '',
      limitComments: 10,
      comments: [],
      loadingMoreComments: false,
      totalComments: 0,
      poetId: '',
      loadingInkers: true,
      views: 0
    };
  }
  componentDidMount() {
    var elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems);
    this.onListenForPoem();
  }
  componentDidUpdate() {
    M.Modal.init(this.modalInkers);
    var elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems);
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#scroll').fadeIn();
      } else {
        $('#scroll').fadeOut();
      }
    });
    $('#scroll').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 600);
      return false;
    });
  }

  async getInkers(poem) {
    let inkers = [];
    if (poem.data().inkers.length < 1) {
      // Poem has no inkers
      this.setState({
        inkers: [],
        loadingInkers: false
      });
    } else {
      // Loop through all inkers
      for (let i in poem.data().inkers) {
        // Document id of inker poet
        const inkerId = await poem.data().inkers[i];
        // Query for that inker document
        let inker = await this.props.firebase.user(inkerId).get();
        // Get document data
        let inkerData = await inker.data();
        // Add document id to data object
        inkerData.uid = inkerId;
        // Push inker to inker array
        await inkers.push({ inker: inkerData, uid: inkerId });
      }
      // Set inkers to state
      // No longer loading inkers
      await this.setState({
        inkers: inkers,
        loadingInkers: false
      });
    }
  }
  async getComments(snapshot, loadingMore) {
    let comments = [];
    // Loop through comments for poem
    for (let i in snapshot.docs) {
      // Get comment data
      const comment = snapshot.docs[i].data();
      // Get poet who gave the comment
      let commenter = await this.props.firebase.user(comment.commenterId).get();
      // Add the comment, poet, and comment id to array
      await comments.push({
        ...comment,
        poet: commenter.data(),
        uid: snapshot.docs[i].id
      });
    }
    // Set comments array to state
    switch (loadingMore) {
      case true:
        this.setState({
          comments: comments,
          totalComments: snapshot.size,
          loadingMoreComments: false
        });
      case false:
        this.setState({
          comments: comments,
          totalComments: snapshot.size,
          loadingComments: false
        });
    }
  }
  updateViews = poem => {
    const parent = this;
    if (localStorage['hasViewed']) {
      // Get hasViewed array from local storage
      let hasViewed = JSON.parse(localStorage.getItem('hasViewed'));
      // Check if poem id is already in hasViewed
      if (hasViewed.includes(poem.id) == false) {
        // If not, add it to hasViewed
        hasViewed.push(poem.id);
        // Set new hasViewed to local storage
        localStorage.setItem('hasViewed', JSON.stringify(hasViewed));
        // Increment total poem views
        parent.props.firebase
          .poem(parent.state.id)
          .update({
            views: this.props.firebase.fieldValue.increment(1)
          })
          .then(() => {
            parent.setState({ views: poem.data().views + 1 });
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      // Create hasViewed array
      let hasViewed = [];
      // Add current poem id to hasViewed array
      hasViewed[0] = poem.id;
      // Set hasViewed to local storage
      localStorage.setItem('hasViewed', JSON.stringify(hasViewed));
      // Update poem to increment total views
      parent.props.firebase
        .poem(parent.state.id)
        .update({
          views: this.props.firebase.fieldValue.increment(1)
        })
        .then(() => {
          parent.setState({ views: poem.data().views + 1 });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  onListenForPoem = () => {
    // Query poem
    const parent = this;
    this.props.firebase
      .poem(this.state.id)
      .get()
      .then(async poem => {
        if (poem.exists) {
          // Set poem data to state
          this.setState({
            views: poem.data().views,
            poem: poem.data(),
            title: poem.data().title,
            text: poem.data().text,
            loadingPoem: false,
            tags: poem.data().tags,
            format: poem.data().format
          });
          // Change page title to poem title
          document.title = poem.data().title + ' - Poetryphile';

          // Update views
          this.updateViews(poem);

          // If there is a current user, check for bookmark of this poem
          if (this.props.firebase.auth.currentUser != null) {
            this.props.firebase
              .user(this.props.firebase.auth.currentUser.uid)
              .get()
              .then(function (doc) {
                // If poem in bookmarks array of current user
                if (doc.data().bookmarks.includes(parent.state.id)) {
                  parent.setState({ bookmarked: true });
                }
                if (doc.data().hideExplicit == true) {
                  var filter = new Badwords();
                  parent.setState(prevState => {
                    let poem = Object.assign({}, prevState.poem); // creating copy of state variable poem
                    poem.text = filter.clean(parent.state.poem.text); // update the text property, assign a new value with explicit words hidden
                    return { poem }; // return new object poem object
                  });
                }
              });
          }

          // Query poem poet
          await this.props.firebase
            .user(poem.data().poetId)
            .get()
            .then(function (author) {
              // Add poet data to the state
              parent.setState({ poet: author.data(), poetId: author.id });
            });

          await this.props.firebase
            .comments()
            .orderBy('createdAt', 'desc')
            .limit(this.state.limitComments)
            .where('poemId', '==', this.state.id)
            .onSnapshot(async snapshot => {
              if (snapshot.size) {
                let loadingMore = false;
                await this.getComments(snapshot, loadingMore);
                await this.getInkers(poem);
              } else {
                await this.getInkers(poem);
                this.setState({ comments: [], loadingComments: false });
                if (poem.data().title.replace(/\s/g, '') == '') {
                  parent.setState({ title: 'Untitled Poem' });
                }
              }
            });
        } else {
          this.setState({
            poem: null,
            loadingInkers: false,
            loadingComments: false,
            loadingPoem: false
          });
        }
      });
  };
  bookmark = async event => {
    this.setState({ isBookmarking: true });
    const poet = await this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .get();

    const newBookmarks = poet.data().bookmarks.slice();
    newBookmarks.push(this.state.id);

    await this.props.firebase
      .bookmarks()
      .add({
        bookmarkerId: this.props.firebase.auth.currentUser.uid,
        poemId: this.state.id,
        bookmarkedAt: this.props.firebase.fieldValue.serverTimestamp()
      })
      .then(bookmark => {
        console.log(bookmark);
      })
      .catch(err => {
        console.log(err);
      });

    await this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .update({
        bookmarks: newBookmarks
      })
      .then(() => {
        this.setState({ isBookmarking: false, bookmarked: true });
        M.toast({ html: 'Poem bookmarked' });
      })
      .catch(err => {
        M.toast({ html: 'Unable to bookmark poem' });
        this.setState({ isBookmarking: false });
      });
  };
  unBookmark = async event => {
    this.setState({
      unbookCount: this.state.unbookCount + 1
    });
    this.setState({ isBookmarking: true });
    const poet = await this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .get();

    const newBookmarks = poet.data().bookmarks.slice();
    newBookmarks.shift(this.state.id);

    await this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .update({
        bookmarks: newBookmarks
      })
      .then(async () => {
        await this.props.firebase.db
          .collection('bookmarks')
          .where('bookmarkerId', '==', this.props.firebase.auth.currentUser.uid)
          .where('poemId', '==', this.state.id)
          .get()
          .then(async snapshot => {
            if (snapshot.size > 0) {
              for (var i in snapshot.docs) {
                const doc = snapshot.docs[i];
                await doc.ref.delete();
              }
            }
          });
      })
      .then(() => {
        this.setState({ bookmarked: false, isBookmarking: false });
        M.toast({ html: 'Poem unbookmarked' });
      })
      .catch(err => {
        console.log(err);
        M.toast({ html: 'Unable to unbookmark poem' });
        this.setState({ isBookmarking: false });
      });
  };

  async updatePoem(poem) {
    try {
      let poemDoc = await this.props.firebase.poem(this.state.id).get(); // Query poem clicked on

      let newInkers = await poemDoc.data().inkers.slice(); // Make copy of current inkers

      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == false // Only increment ink if inker has not already inked
      ) {
        newInkers.push(this.props.firebase.auth.currentUser.uid); // Add new inker to inkers array
        await this.props.firebase.poem(this.state.id).update({
          inkers: newInkers,
          ink: this.props.firebase.fieldValue.increment(1)
        });
      }
    } catch (err) {
      console.log('Error inking');
      this.setState({ isInking: false });
    }
  }
  async addInk(poem) {
    try {
      let author = await poem.poetRef.get();
      await this.props.firebase.inks().add({
        // Add ink document to represent given ink
        inkerRef: this.props.firebase.db
          .collection('users')
          .doc(this.props.firebase.auth.currentUser.uid), // Reference to inker
        poemRef: this.props.firebase.db.collection('poems').doc(this.state.id), // Reference to poem
        createdAt: this.props.firebase.fieldValue.serverTimestamp(), // Ink action timestamp
        inkerId: this.props.firebase.auth.currentUser.uid, // id of inker
        poemId: this.state.id, // id of poem
        authorId: author.id // id of author
      });
    } catch (err) {
      this.setState({ isInking: false });

      // If error, remove inker id from inkers array that was added in updatePoem function
      let poemDoc = await this.props.firebase.poem(this.state.id).get(); // Query peom being given ink

      let newInkers = await poemDoc.data().inkers.slice(); // Get copy of inkers
      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == true // If inkerId was successfully added to inker array
      ) {
        let filteredInkers = newInkers.filter(
          id => id !== this.props.firebase.auth.currentUser.uid // remove it
        );
        await this.props.firebase.poem(this.state.id).update({
          inkers: filteredInkers,
          ink: this.props.firebase.fieldValue.increment(-1)
        });
      }

      this.setState({ isInking: false });
    }

    try {
      let author = await poem.poetRef.get(); // Query author of poem being given ink
      await this.props.firebase.user(author.id).update({
        ink: this.props.firebase.fieldValue.increment(1) // Increment their ink
      });
      this.setState({ isInking: false });
    } catch (err) {
      // If error, remove inker id from inkers array that was added in updatePoem function

      this.setState({ isInking: false });

      let poemDoc = await this.props.firebase.poem(this.state.id).get(); // Query poem being given ink

      let newInkers = await poemDoc.data().inkers.slice(); // Get copy of inkers
      if (
        poemDoc
          .data()
          .inkers.includes(this.props.firebase.auth.currentUser.uid) == true // If inkerId was successfully added to inker array
      ) {
        let filteredInkers = newInkers.filter(
          id => id !== this.props.firebase.auth.currentUser.uid // Filter inkerId out of inker array
        );
        await this.props.firebase.poem(this.state.id).update({
          inkers: filteredInkers,
          ink: this.props.firebase.fieldValue.increment(-1) // Decrement the ink of the poem because ink action failed
        });
      }
      await this.props.firebase
        .inks()
        .where('inkerId', '==', this.props.firebase.auth.currentUser.uid)
        .where('poemId', '==', this.state.id)
        .onSnapshot(async snapshot => {
          if (snapshot.size) {
            for (var i in snapshot.docs) {
              const doc = snapshot.docs[i];
              await doc.ref.delete(); // Delete the ink document that was created because ink action failed
            }
          }
        });
    }
  }

  onInk = async (event, poem) => {
    event.preventDefault();

    this.setState({ isInking: true });

    await this.updatePoem(poem);

    await this.addInk(poem);

    this.setState({ isInking: false });

    let loadingMore = false;

    this.onListenForPoem(loadingMore);
  };
  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };
  loadMoreComments = () => {
    this.setState(
      state => ({
        limitComments: state.limitComments + 5,
        loadingMoreComments: true
      }),
      this.addComments
    );
  };
  addComments = () => {
    this.props.firebase
      .comments()
      .orderBy('createdAt', 'desc')
      .limit(this.state.limitComments)
      .where('poemId', '==', this.state.id)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let loadingMore = true;
          this.getComments(snapshot, loadingMore);
        }
      });
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.setState({ postingComment: true });
    const parent = this;
    this.props.firebase
      .comments()
      .add({
        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
        poetId: this.state.poetId,
        commenterId: authUser.uid,
        poetRef: this.props.firebase.db
          .collection('users')
          .doc(this.state.poetId),
        commenterRef: this.props.firebase.db
          .collection('users')
          .doc(this.props.firebase.auth.currentUser.uid),
        poemRef: this.props.firebase.db.collection('poems').doc(this.state.id),
        poemId: this.state.id,
        text: this.state.comment
      })
      .then(() => {
        document.querySelector('#modal-post-comment').M_Modal.close();

        this.props.firebase
          .poem(this.state.id)
          .update({
            totalComments: this.props.firebase.fieldValue.increment(1)
          })
          .then(() => {
            M.toast({ html: 'Comment shared' });
            this.props.firebase
              .comments()
              .orderBy('createdAt', 'desc')
              .limit(this.state.limitComments)
              .where('poemId', '==', this.state.id)
              .onSnapshot(snapshot => {
                if (snapshot.size) {
                  let loadingMore = false;
                  this.getComments(loadingMore);
                }
              });
          })
          .then(() => {
            this.props.firebase
              .poem(this.state.id)
              .get()
              .then(poem => {
                if (poem.exists) {
                  this.setState({ comment: '' });
                  let loadingMore = false;
                  this.getComments(loadingMore);
                }
              });
          })
          .then(() => {
            parent.setState({ postingComment: false });
          });
      })
      .catch(err => {
        parent.setState({ postingComment: false });
        M.toast({ html: 'Unable to post comment' });
      });
  };
  render() {
    const {
      poem,
      loadingPoem,
      loadingComments,
      isBookmarking,
      postingComment,
      comment,
      comments,
      limitComments,
      loadingMoreComments,
      poemId,
      loadingInkers
    } = this.state;
    const isInvalid = comment.replace(/\s/g, '').length < 1;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loadingPoem == true ||
              loadingComments == true ||
              loadingInkers == true ? (
                <Preloader />
              ) : (
                <div>
                  {poem == null ? (
                    <FourOFour />
                  ) : (
                    <div>
                      {poem.type == 'public' ? (
                        <div>
                          <div
                            id='scroll'
                            style={{ top: '25px' }}
                            className='fixed-action-btn horizontal'>
                            <a
                              style={{ backgroundColor: '#da694b' }}
                              className='btn-floating btn-small'>
                              <i className='large material-icons'>
                                arrow_drop_up
                              </i>
                            </a>
                          </div>
                          <ul id='dropdown2' className='dropdown-content'>
                            <li>
                              <a href='#!'>Report</a>
                            </li>
                          </ul>
                          <ul
                            id='toolbar'
                            style={{ marginTop: '50px', overflow: 'visible' }}
                            className='collection container'>
                            <div>
                              {authUser != undefined ? (
                                <div>
                                  {/* <span
                                    className='right'
                                    style={{ marginBottom: '10px' }}>
                                    <a
                                      id='more'
                                      href='#!'
                                      ref={dropdown => {
                                        this.dropdown = dropdown;
                                      }}
                                      className='dropdown-trigger btn btn-floating waves-effect btn-flat close'
                                      data-target='dropdown2'>
                                      <i
                                        id='more-icon'
                                        className='material-icons'>
                                        more_vert
                                      </i>
                                    </a>
                                  </span> */}
                                  {this.state.bookmarked ? (
                                    <span
                                      className='right'
                                      style={{ marginBottom: '10px' }}>
                                      <a
                                        disabled={isBookmarking}
                                        onClick={event =>
                                          this.unBookmark(event)
                                        }
                                        id='bookmark'
                                        className='btn btn-floating waves-effect btn-flat close'>
                                        <i
                                          style={{ color: '#da694b' }}
                                          id='bookmark-icon'
                                          className='material-icons'>
                                          bookmark
                                        </i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span
                                      className='right'
                                      style={{ marginBottom: '10px' }}>
                                      <a
                                        disabled={isBookmarking}
                                        onClick={event => this.bookmark(event)}
                                        id='bookmark'
                                        className='btn btn-floating waves-effect btn-flat close'>
                                        <i
                                          id='bookmark-icon'
                                          className='material-icons'>
                                          bookmark_border
                                        </i>
                                      </a>
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <p className='award-show right'>
                                {this.state.views} views
                              </p>
                            </div>
                          </ul>
                          <br />
                          <br />
                          <br />
                          <ul
                            style={{ marginTop: '-10px' }}
                            id='poem-show'
                            className='collection with-header container'>
                            <li
                              style={{
                                borderBottom: 'none',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap'
                              }}
                              className='collection-header'
                              id='title-container'>
                              <b>
                                <p
                                  style={{ marginTop: '11px' }}
                                  id='show-poem-title'>
                                  {poem.title}
                                </p>
                              </b>
                            </li>
                          </ul>
                          <ul
                            style={{ borderTop: 'none' }}
                            className='collection container'>
                            <li
                              style={{
                                borderBottom: 'none',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap'
                              }}
                              className='collection-item'>
                              {this.state.format == 'format_align_left' && (
                                <div
                                  id='text'
                                  style={{
                                    marginTop: '-10px',
                                    textAlign: 'left'
                                  }}>
                                  <blockquote>{poem.text}</blockquote>
                                </div>
                              )}
                              {this.state.format == 'format_align_center' && (
                                <div
                                  id='text'
                                  style={{
                                    marginTop: '-10px',
                                    textAlign: 'center'
                                  }}>
                                  <blockquote>{poem.text}</blockquote>
                                </div>
                              )}
                              {this.state.format == 'format_align_right' && (
                                <div
                                  id='text'
                                  style={{
                                    marginTop: '-10px',
                                    textAlign: 'right'
                                  }}>
                                  <blockquote>{poem.text}</blockquote>
                                </div>
                              )}
                              {poem.tags.length > 0 && (
                                <div>
                                  {poem.isClassic == false && (
                                    <div style={{ display: 'inline-block' }}>
                                      <span style={{ fontSize: '17px' }}>
                                        Tags:
                                      </span>
                                      &nbsp;
                                    </div>
                                  )}
                                  {poem.tags.map((tag, index) => {
                                    return (
                                      <div
                                        style={{ display: 'inline-block' }}
                                        key={index}
                                        className='chip'>
                                        {tag.tag}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {poem.isClassic == true && (
                                <p style={{ fontSize: '17px' }}>
                                  Reprinted by use of public domain
                                </p>
                              )}
                            </li>
                          </ul>
                          <Modal
                            poem={poem}
                            showPoemInkers={true}
                            inkers={this.state.inkers}
                          />
                          <div
                            ref={modalInkers => {
                              this.modalInkers = modalInkers;
                            }}
                            id='modal-inkers'
                            className='modal'>
                            <div className='modal-content'>
                              <h4 id='login-header'>
                                Inkers&nbsp;
                                <span
                                  style={{
                                    fontSize: '16px',
                                    color: '#413043'
                                  }}>{`(${poem.ink})`}</span>
                                <a
                                  id='modal-close'
                                  className='modal-close btn btn-floating waves-effect btn-flat close'>
                                  <i className='small material-icons'>close</i>
                                </a>
                              </h4>
                              {this.state.inkers == null ||
                                (this.state.inkers.length < 1 && (
                                  <h4 id='no-following'>
                                    This poem does not have any inkers!
                                  </h4>
                                ))}
                              {this.state.inkers != null &&
                                this.state.inkers.map((inker, index) => (
                                  <div key={index}>
                                    <a
                                      href={`/poets/@${inker.inker.penName}`}
                                      className='inker'>
                                      {inker.inker.penName}
                                      <span style={{ fontWeight: 'bold' }}>
                                        <div className='ink-line ink-score'>
                                          <span
                                            style={{
                                              color: '#da694b',
                                              display: 'inline-block',
                                              paddingRight: '3px',
                                              paddingLeft: '5px'
                                            }}>
                                            (
                                          </span>
                                          <span
                                            style={{
                                              display: 'inline-block',
                                              color: '#413043',
                                              fontSize: '17px'
                                            }}>
                                            {inker.inker.ink}
                                            <svg
                                              style={{
                                                width: '50px',
                                                marginLeft: '-15px',
                                                position: 'absolute',
                                                zIndex: 0
                                              }}
                                              id='Layer_1'
                                              data-name='Layer 1'
                                              xmlns='http://www.w3.org/2000/svg'
                                              viewBox='0 0 2000 2000'>
                                              <title>Ink</title>
                                              <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                                            </svg>
                                          </span>
                                          <span
                                            style={{
                                              color: '#da694b',
                                              display: 'inline-block',
                                              paddingLeft: '20px'
                                            }}>
                                            )
                                          </span>
                                        </div>
                                      </span>
                                    </a>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <ul
                            id='poet-details'
                            style={{ borderTop: 'none' }}
                            className='collection container'>
                            <li className='collection-item avatar'>
                              <img
                                id='poet-profile-img'
                                className='circle'
                                src={this.state.poet.avatar}></img>
                              <span
                                style={{ fontSize: '17px' }}
                                className='title'>
                                By{' '}
                                <a
                                  style={{ fontSize: '17px' }}
                                  href={`/poets/@${this.state.poet.penName}`}
                                  className='poet-browse-name'>
                                  {this.state.poet.penName}
                                  <div className='ink-line ink-score'>
                                    <span
                                      style={{
                                        color: '#da694b',
                                        display: 'inline-block',
                                        paddingRight: '3px',
                                        paddingLeft: '5px'
                                      }}>
                                      (
                                    </span>
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        color: '#413043',
                                        fontSize: '17px'
                                      }}>
                                      {this.state.poet.ink}
                                    </span>
                                    <svg
                                      style={{
                                        width: '50px',
                                        marginLeft: '-15px',
                                        position: 'absolute',
                                        zIndex: 0
                                      }}
                                      id='Layer_1'
                                      data-name='Layer 1'
                                      xmlns='http://www.w3.org/2000/svg'
                                      viewBox='0 0 2000 2000'>
                                      <title>ink drop</title>
                                      <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                                    </svg>
                                    <span
                                      style={{
                                        color: '#da694b',
                                        display: 'inline-block',
                                        paddingLeft: '20px'
                                      }}>
                                      )
                                    </span>
                                  </div>
                                </a>
                              </span>
                              <br />
                              {poem.isClassic == false ? (
                                <p className='date created-at-show'>
                                  {moment(poem.createdAt.toDate()).fromNow()}
                                </p>
                              ) : (
                                <p className='date created-at-show'>
                                  {poem.classicDate}
                                </p>
                              )}

                              {authUser != null ? (
                                <div>
                                  {this.state.poem.inkers.includes(
                                    authUser.uid
                                  ) ? (
                                    <svg
                                      className='right'
                                      style={{
                                        width: '100px',
                                        marginTop: '-72px',
                                        marginRight: '25px'
                                      }}
                                      id='Layer_1'
                                      data-name='Layer 1'
                                      xmlns='http://www.w3.org/2000/svg'
                                      viewBox='0 0 2000 2000'>
                                      <title>ink drop</title>
                                      <path
                                        className='ink-upvote ink-upvoted'
                                        d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z'
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      onClick={event => this.onInk(event, poem)}
                                      className='right'
                                      style={{
                                        width: '100px',
                                        marginTop: '-72px',
                                        marginRight: '25px'
                                      }}
                                      id='Layer_1'
                                      data-name='Layer 1'
                                      xmlns='http://www.w3.org/2000/svg'
                                      viewBox='0 0 2000 2000'>
                                      <title>ink drop</title>
                                      <path
                                        className='ink-upvote'
                                        d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z'
                                      />
                                    </svg>
                                  )}
                                  <a
                                    className={[
                                      'right',
                                      'modal-trigger',
                                      authUser != undefined &&
                                        poem.inkers.includes(authUser.uid) &&
                                        'orange-ink-count'
                                    ]
                                      .filter(e => e)
                                      .join(' ')}
                                    id='ink-count'
                                    style={{
                                      marginTop: '-32px',
                                      marginRight: '-115px',
                                      zIndex: '999999',
                                      fontSize: '17px'
                                    }}
                                    data-target='modal-inkers'>
                                    {poem.ink} ink
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  <svg
                                    className='right modal-trigger'
                                    data-target='modal-login'
                                    style={{
                                      width: '100px',
                                      marginTop: '-72px',
                                      marginRight: '25px'
                                    }}
                                    id='Layer_1'
                                    data-name='Layer 1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 2000 2000'>
                                    <title>ink drop</title>
                                    <path
                                      className='ink-upvote'
                                      d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z'
                                    />
                                  </svg>
                                  <a
                                    className={['right', 'modal-trigger']
                                      .filter(e => e)
                                      .join(' ')}
                                    id='ink-count'
                                    style={{
                                      marginTop: '-32px',
                                      marginRight: '-115px',
                                      zIndex: '999999',
                                      fontSize: '17px'
                                    }}
                                    data-target='modal-inkers'>
                                    {poem.ink} ink
                                  </a>
                                </div>
                              )}
                            </li>
                          </ul>

                          <br />
                          <ul
                            id='poems'
                            className='collection with-header container'>
                            <li
                              style={{ height: '90px' }}
                              className='collection-header'>
                              <p
                                style={{ marginTop: '12px' }}
                                id='my-poems'
                                className='futura'>
                                Comments&nbsp;
                                <span
                                  style={{
                                    color: '#413043',
                                    fontSize: '20px'
                                  }}>
                                  {'(' + this.state.totalComments + ')'}
                                </span>
                              </p>
                              {authUser != undefined && (
                                <a
                                  style={{ marginTop: '-63px' }}
                                  className='right modal-trigger'
                                  data-target='modal-post-comment'>
                                  <i
                                    style={{ color: '#da694b' }}
                                    className='material-icons'>
                                    add
                                  </i>
                                </a>
                              )}
                            </li>
                            {this.state.totalComments < 1 ? (
                              <li
                                id='no-public-wrapper'
                                className='collection-item'>
                                <center>
                                  <svg
                                    style={{
                                      width: '200px',
                                      marginTop: '-20px'
                                    }}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 2000 2000'>
                                    <title>
                                      Poetryphile Icon Notifications
                                    </title>
                                    <g id='Layer_3' data-name='Layer 3'>
                                      <path
                                        d='M1451.59,489.68H548.41C433.3,489.68,340,583,340,698.11v347.38c0,115.1,93.33,208.42,208.43,208.42l73.8,214.72a61.78,61.78,0,0,0,58.42,41.69h0a61.79,61.79,0,0,0,43.44-17.85l241.19-238.56h486.33c115.11,0,208.43-93.31,208.43-208.42V698.11C1660,583,1566.7,489.68,1451.59,489.68ZM913,1184.44,681.64,1412.12l-75.55-226.68-57.68-1a139,139,0,0,1-138.95-139V698.11a139,139,0,0,1,139-139h903.18a139,139,0,0,1,139,139v347.38a139,139,0,0,1-139,139H936.47'
                                        style={{ fill: '#413043' }}
                                      />
                                      <polygon
                                        points='937.4 1184.44 936.47 1184.44 936.93 1183.98 937.4 1184.44'
                                        style={{ fill: '#413043' }}
                                      />
                                      <rect
                                        x='548.41'
                                        y='698.11'
                                        width='903.18'
                                        height='69.48'
                                        style={{ fill: '#da694b' }}
                                      />
                                      <rect
                                        x='548.41'
                                        y='837.06'
                                        width='903.18'
                                        height='69.48'
                                        style={{ fill: '#da694b' }}
                                      />
                                      <rect
                                        x='548.41'
                                        y='976.01'
                                        width='694.76'
                                        height='69.48'
                                        style={{ fill: '#da694b' }}
                                      />
                                    </g>
                                  </svg>
                                  <h4 id='no-comments'>
                                    This poem has no comments yet!
                                  </h4>
                                </center>
                              </li>
                            ) : (
                              <Comments
                                comments={comments}
                                totalComments={this.state.totalComments}
                                loadingMoreComments={loadingMoreComments}
                                limitComments={limitComments}
                                loadMoreComments={this.loadMoreComments}
                              />
                            )}
                          </ul>
                          <br />
                          <Modal
                            authUser={authUser}
                            commentModal={true}
                            isInvalid={isInvalid}
                            postingComment={postingComment}
                            onSubmit={this.onSubmit}
                            onChange={this.onChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <FourOFour />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const Comments = ({
  comments,
  totalComments,
  limitComments,
  loadingMoreComments,
  loadMoreComments
}) => (
  <ul style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
    {comments &&
      comments.map(comment => (
        <li key={comment.uid} className='collection-item avatar'>
          <img
            id='poet-profile-img'
            className='circle'
            src={comment.poet.avatar}></img>
          <a
            style={{ fontSize: '17px' }}
            className='comment-pen-name'
            href={`/poets/@${comment.poet.penName}`}>
            {comment.poet.penName}
            <span style={{ fontWeight: 'bold' }}>
              <div className='ink-line ink-score'>
                <span
                  style={{
                    color: '#da694b',
                    display: 'inline-block',
                    paddingRight: '3px',
                    paddingLeft: '5px'
                  }}>
                  (
                </span>
                <span
                  style={{
                    display: 'inline-block',
                    color: '#413043',
                    fontSize: '17px'
                  }}>
                  {comment.poet.ink}
                  <svg
                    style={{
                      width: '50px',
                      marginLeft: '-15px',
                      position: 'absolute',
                      zIndex: 0
                    }}
                    id='Layer_1'
                    data-name='Layer 1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 2000 2000'>
                    <title>Ink</title>
                    <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#da694b',
                    display: 'inline-block',
                    paddingLeft: '20px'
                  }}>
                  )
                </span>
              </div>
            </span>
          </a>
          <br />
          {comment.createdAt == null ? (
            <p style={{ marginTop: '5px' }} className='created-at'>
              a few seconds ago
            </p>
          ) : (
            <p style={{ marginTop: '5px' }} className='created-at'>
              {moment(comment.createdAt.toDate()).fromNow()}
            </p>
          )}
          <br />
          <div style={{ marginTop: '3px', fontSize: '17px' }}>
            {comment.text}
          </div>
        </li>
      ))}
    {comments && limitComments >= totalComments && <TheEnd />}
    {comments && totalComments > limitComments && loadingMoreComments == false && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='load-more' onClick={loadMoreComments}>
            Load More
          </a>
        </center>
      </li>
    )}
    {comments &&
      totalComments + 5 > limitComments &&
      loadingMoreComments == true && (
        <li className='collection-item'>
          <center>
            <a href='#!' className='loading-more'>
              Loading more...
            </a>
          </center>
        </li>
      )}
  </ul>
);

export default compose(withFirebase)(ShowPoem);
