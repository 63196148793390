import React, { Component } from 'react';

class Tos extends Component {
  componentDidMount() {
    document.title = 'TOS' + ' - Poetryphile';
  }
  render() {
    return (
      <div>
        <ul
          id='poems'
          style={{ marginTop: '50px' }}
          className='collection with-header container'>
          <li style={{ height: '90px' }} className='collection-header'>
            <p id='my-poems' className='futura' style={{ marginTop: '13px' }}>
              Terms of Service
            </p>
          </li>
          <li style={{ fontSize: '17px' }} className='collection-item'>
            <p>
              <b>
                <span>1. The acceptance of the Terms</span>
              </b>
            </p>
            <p>
              In order to use the Services, you must firstly agree to these
              Terms. You must not use the Services if you do not accept the
              Terms. You can accept the Terms by:
            </p>
            <p>
              1. registration and subsequent activation of the User Account;
            </p>
            <p>2. clicking to accept or agree to the Terms; or</p>
            <p>
              3. by using the Services. In this case, you understand and agree
              that Poetryphile will perceive your use of the Services as
              acceptance of the Terms from that point onwards.
            </p>
            <p>
              By using the Services, you agree to follow these Terms. In the
              event of a breach of the Terms, we have the right to remove any
              Content you have submitted to Poetryphile and suspend or delete
              your User Account.
            </p>
            <p>
              <b>
                <span>2. Your rights and obligations</span>
              </b>
            </p>
            <p>
              You acknowledge and agree that you are responsible for keeping
              your username and password to your User Account secure.
            </p>
            <p>
              By registering and using the Services, you agree to let us collect
              and use information about yourself (such as identification or
              contact details) according to our{' '}
              <a
                style={{
                  color: '#da694b'
                }}
                href='/privacy'>
                Privacy Policy.
              </a>
            </p>
            <p>
              You cannot post Content you did not create to the Poetryphile
              Website, however, you are welcome to post Content that you have
              published elsewhere, as long as you have all the rights you need
              to do so. By posting Content to Services, you guarantee that doing
              so does not conflict with any other agreement you have entered
              into.
            </p>
            <p>
              You also acknowledge and agree that Poetryphile is not liable for
              any Content uploaded by you.
            </p>
            <p>
              You are solely responsible for your interactions with other
              Poetryphile Users. You are not allowed to spam other Poetryphile
              Users with unwanted emails, comments or other forms of harassing
              communications. You are not allowed to change, translate,
              reproduce, distribute or otherwise create derivative works of
              other Users' Content unless you obtain express permission from the
              author of such Content.
            </p>
            <p>
              You understand that by using the Services you may be exposed to
              Content that you may find offensive, indecent, sexually explicit
              or objectionable and that, in this respect, you use the Services
              at your own risk.
            </p>
            <p>
              <b>
                <span>3. Content Rights</span>
              </b>
            </p>
            <p>
              You retain copyright and any other rights you hold in the Content
              which you submit, post or display on or through, the Services.
            </p>
            <p>
              You agree that we may enable advertising on the Services,
              including in connection with the display of your Content or other
              information. We may also use your Content for a promotion of
              Poetryphile, which may include promotion of our products, content
              and Services.
            </p>
            <p>
              We are not entitled to commercially sublicense or sell your
              Content to third parties without your explicit permission.
            </p>
          </li>
        </ul>
      </div>
    );
  }
}

export default Tos;
