import React from 'react';

import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../session';

const BrowseNavigation = () => {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <nav className='nav-center nav-extended'>
          <div className='nav-wrapper container'>
            <ul>
              <li>
                <a
                  className='splotching'
                  style={{ borderTop: '5px solid #e7c7be' }}
                  href={ROUTES.LANDING}>
                  Splotching Poems
                </a>
              </li>
              <li>
                <a
                  className='new'
                  style={{ borderTop: '5px solid #f89076' }}
                  href={ROUTES.NEW}>
                  New Poems
                </a>
              </li>
              {authUser != null && (
                <li>
                  <a
                    style={{ borderTop: '5px solid #da694b' }}
                    className='following'
                    href={ROUTES.FOLLOWING}>
                    Following
                  </a>
                </li>
              )}
              <li>
                <a
                  className='ink-nav'
                  style={{ borderTop: '5px solid #c04725' }}
                  href={ROUTES.INK}>
                  Ink Leaderboard
                </a>
              </li>
              {/* <li>
                <a
                  className='contests-nav'
                  style={{ borderTop: '5px solid #9c3214' }}
                  href={{ pathname: ROUTES.CONTESTS }}>
                  Contests
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      )}
    </AuthUserContext.Consumer>
  );
};

export default BrowseNavigation;
