// Imports
import React, { Component } from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import $ from 'jquery';
import M from 'materialize-css';

import BrowseNavigation from '../partials/BrowseNavigation';
import Preloader from '../partials/Preloader';
import Modal from '../partials/Modal';
import TheEnd from '../partials/TheEnd';

import { withFirebase } from '../config';
import { AuthUserContext } from '../session';

class Contests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      contests: [],
      loadingMore: false,
      limit: 10
    };
  }
  componentDidMount() {
    document.title = 'Contests' + ' - Poetryphile';
    let loadingMore = false;
    this.onListenForContests(loadingMore);
  }
  componentDidUpdate() {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    var elems = document.querySelectorAll('.datepicker');
    M.Datepicker.init(elems, {
      autoClose: true,
      minDate: currentDate,
      container: 'body'
    });
  }
  onListenForContests = async loadingMore => {
    let contests = await this.props.firebase.db
      .collection('contests')
      .limit(this.state.limit);

    loadingMore == false
      ? contests
          .get()
          .then(snapshot => {
            snapshot.size
              ? this.onGetContests(snapshot, loadingMore)
              : this.setState({ loading: false, contests: null });
          })
          .then(() => {
            console.log('Fetched contests');
          })
          .catch(err => {
            console.log('Error fetching contests' + err);
            this.setState({ contests: null, loading: false });
          })
      : contests
          .get()
          .then(snapshot => {
            snapshot.size
              ? this.onGetContests(snapshot, loadingMore)
              : this.setState({ loading: false });
          })
          .then(() => {
            console.log('Fetched contests');
          })
          .catch(err => {
            console.log('Error fetching more contests' + err);
            this.setState({ contests: null, loading: false });
          });
  };

  onSubmit = async event => {
    event.preventDefault();
    event.preventDefault();
    let currentUser = await this.props.firebase
      .user(this.props.firebase.auth.currentUser.uid)
      .get();
  };
  onChange = () => {};
  render() {
    const { loading } = this.state;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loading == true ? (
                <Preloader />
              ) : (
                <div>
                  <BrowseNavigation />
                  <Modal filterContestModal={true} />
                  <Modal
                    createContestModal={true}
                    authUser={authUser}
                    onSubmit={this.onSubmit}
                    onChange={this.onChange}
                  />
                  <ul
                    id='toolbar'
                    style={{ marginTop: '50px', overflow: 'visible' }}
                    className='collection container'>
                    <div>
                      <span className='right' style={{ marginBottom: '10px' }}>
                        <a
                          style={{
                            background: '#da694b',
                            borderRadius: '18px'
                          }}
                          className='waves-effect z-depth-0 btn modal-trigger'
                          data-target='modal-filter'>
                          <i
                            style={{ color: 'white' }}
                            className='material-icons left'>
                            tune
                          </i>
                          filter
                        </a>
                      </span>
                    </div>
                  </ul>
                  <ul
                    id='poems'
                    style={{ marginTop: '85px' }}
                    className='collection with-header container'>
                    <li
                      style={{ height: '90px' }}
                      className='collection-header'>
                      <p
                        style={{ marginTop: '13px' }}
                        id='my-poems'
                        className='futura'>
                        Contests
                      </p>
                      {authUser != null && (
                        <a
                          id='write-contest-btn'
                          className='btn z-depth-0 right hide-on-small-only modal-trigger'
                          data-target='modal-create-contest'>
                          <i
                            id='create-icon'
                            style={{ color: '#da694b' }}
                            className='material-icons left'>
                            add
                          </i>
                          Create contest
                        </a>
                      )}
                    </li>
                    <li className='collection-item' id='no-public'>
                      <center>
                        <svg
                          style={{ width: '200px', marginTop: '-5px' }}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 2000 2000'>
                          <title>Poetryphile Challenge</title>
                          <g id='Layer_2' data-name='Layer 2'>
                            <polygon
                              points='702 1424.18 587.85 1538.31 473.55 1424.04 473.55 1424.01 473.53 1423.99 559.17 1338.35 587.68 1309.83 702 1424.18'
                              style={{ fill: '#da694b' }}
                            />
                            <path
                              d='M473.53,1424l0,0,85.62-85.66Zm803-800,111.39,111.39V624Zm-803,800,0,0,85.62-85.66Z'
                              style={{ fill: '#413043' }}
                            />
                            <path
                              d='M1526.51,1309.79l57.22-57.11L1355,1023.92l-57.22,57.22-69.19-69.21,159.35-159.36V623.91H1159.27L1000,783.24,840.72,623.91H612.06V852.57l159.35,159.36-69.19,69.21L645,1023.92,416.26,1252.68l57.22,57.11L359.23,1424,587.9,1652.7,702,1538.45l57.12,57.13,228.76-228.76-57.12-57.11L1000,1240.5l69.22,69.21-57.12,57.11,228.76,228.76,57.12-57.13,114.13,114.25L1640.76,1424ZM1387.91,624V735.34L1276.52,624Zm-775.83,0H723.47L612.08,735.34Zm80.81,195.37V704.74H807.22L942.86,840.41,828.47,954.85Zm250,364.08-69.22,69.19-57.12,57.12,57,57.11L759.15,1481.26l-57.12-57L587.9,1538.36l0,0L473.55,1424v0l0,0,85.64-85.64,28.51-28.52,0,0-57.13-57.11L645,1138.25l57.22,57.12,126.32-126.32,57.05-57.1L1000,897.56l57.15-57.15,135.64-135.67H1307.1V819.32L1171.52,954.85,1114.4,1012,1000,1126.32ZM1526.44,1424v0l-114.35,114.32L1298,1424.22l-57.12,57-114.42-114.44,57-57.11-57.12-57.12-69.22-69.19,114.34-114.35,126.32,126.32,57.22-57.12,114.42,114.43-57.13,57.11,0,0L1526.46,1424Z'
                              style={{ fill: '#413043' }}
                            />
                            <polygon
                              points='1387.91 623.95 1387.91 735.34 1276.52 623.95 1387.91 623.95'
                              style={{ fill: '#da694b' }}
                            />
                            <polygon
                              points='1526.46 1423.99 1526.44 1424.01 1412.12 1538.31 1297.98 1424.18 1412.31 1309.83 1526.46 1423.99'
                              style={{ fill: '#da694b' }}
                            />
                            <path
                              d='M612.08,624V735.34L723.47,624Zm828.74,714.4,85.62,85.66,0,0Z'
                              style={{ fill: '#413043' }}
                            />
                            <polygon
                              points='723.47 623.95 612.08 735.34 612.08 623.95 723.47 623.95'
                              style={{ fill: '#da694b' }}
                            />
                            <path
                              d='M1549.85,740.88a59.52,59.52,0,0,1-11.67-1.16c-15.75-3.17-43.93-15.76-55.62-64.51-15.9-66.27-49-113.47-98.27-140.27-35.6-19.36-66.51-20.57-66.81-20.58a31.94,31.94,0,0,1,.7-63.88h.74c4.67.1,47.09,1.79,95.89,28.31a242.15,242.15,0,0,1,75,63.5c25.4,32.3,43.87,72,54.9,118,2.23,9.28,4.87,13.91,6.38,16,2.92-1.72,8.18-6.43,12.33-15.41,3.79-8.21,11-30.21-3.84-57.07-11.2-20.23-23.48-40.38-35.35-59.86-19.45-31.92-37.82-62.06-48.69-88.28-6.77-16.31-10.3-30.34-10.8-42.89-.93-23.4,8.77-38.21,17.08-46.52,11.1-11.1,25.68-17.58,42.16-18.75,11.58-.82,24.26,1,37.7,5.32,36.51,11.82,66.86,39.15,83.26,75l21.73,47.5a31.95,31.95,0,0,1-58.1,26.58l-21.73-47.5c-9-19.6-25.31-34.47-44.83-40.79-6.7-2.17-11.08-2.52-13.38-2.39.36,4,2.26,13.05,10.72,30.6,9.57,19.86,24.08,43.66,39.44,68.87,12.22,20,24.85,40.77,36.69,62.18,12.74,23,18.48,48.34,16.62,73.23-1.64,22.06-9.37,43.53-21.75,60.45C1602,718,1591.64,727.1,1580.45,733A65.5,65.5,0,0,1,1549.85,740.88Z'
                              style={{ fill: '#413043' }}
                            />
                            <path
                              d='M682.49,514.32v0h.08c-2.25.1-34.09,2-69.34,21.92-48,27.06-80.2,73.79-95.82,138.92-11.7,48.75-39.86,61.34-55.62,64.51a59.61,59.61,0,0,1-11.68,1.15A65.45,65.45,0,0,1,419.52,733c-11.19-5.9-21.52-15-29.88-26.46-12.38-16.92-20.11-38.39-21.76-60.45-1.86-24.89,3.89-50.22,16.62-73.23,11.85-21.41,24.48-42.14,36.7-62.18,15.36-25.21,29.86-49,39.43-68.87,8.47-17.55,10.37-26.63,10.72-30.61-2.3-.12-6.67.23-13.37,2.4-19.52,6.32-35.86,21.19-44.83,40.79l-21.73,47.49a31.95,31.95,0,1,1-58.1-26.58l21.73-47.49c16.4-35.85,46.75-63.18,83.25-75,13.45-4.35,26.13-6.14,37.71-5.32,16.48,1.17,31.06,7.65,42.16,18.75,8.31,8.31,18,23.12,17.08,46.52-.5,12.55-4,26.58-10.8,42.89-10.87,26.22-29.25,56.37-48.7,88.28-11.87,19.48-24.14,39.62-35.34,59.86-14.85,26.86-7.63,48.86-3.84,57.07,4.15,9,9.41,13.7,12.33,15.41,1.51-2,4.15-6.67,6.38-16,11-46,29.5-85.7,54.9-118a241.92,241.92,0,0,1,75-63.5c43.72-23.76,82.33-27.59,93.25-28.2,1.27-.08,2.16-.1,2.65-.11a31.93,31.93,0,0,1,1.43,63.83Z'
                              style={{ fill: '#413043' }}
                            />
                            <path
                              d='M681.06,450.49c-.49,0-1.38,0-2.65.11C679.29,450.52,680.17,450.49,681.06,450.49Z'
                              style={{ fill: '#413043' }}
                            />
                          </g>
                        </svg>
                        <h4 id='no-public-poems' className='futura'>
                          There are no contests!
                        </h4>
                      </center>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default compose(withFirebase)(Contests);
