import React, { Component } from 'react';
import { compose } from 'recompose';
import $ from 'jquery';

import BrowseNavigation from '../partials/BrowseNavigation';
import Preloader from '../partials/Preloader';
import Modal from '../partials/Modal';
import TheEnd from '../partials/TheEnd';

import { withFirebase } from '../config';
import { AuthUserContext } from '../session';

class Ink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingMore: false,
      poets: [],
      limit: 10,
      totalPoets: 0,
      timeFrame: 'today',
      filter: 'all'
    };
    // Bind time filter function to this
    this.onSetTimeFrame = this.onSetTimeFrame.bind(this);
  }
  componentDidMount() {
    document.title = 'Ink Leaderboard' + ' - Poetryphile';
    let loadingMore = false;
    this.onListenForPoets(loadingMore);
  }
  componentDidUpdate() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#scroll').fadeIn();
      } else {
        $('#scroll').fadeOut();
      }
    });
    $('#scroll').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 600);
      return false;
    });
  }
  async onGetPoets(snapshot, loadingMore) {
    try {
      let poets = [];
      for (let i in snapshot.docs) {
        // Loop through poets in snapshot

        const poet = await snapshot.docs[i].data();

        let totalPoems = poet.totalPublic; // Total public poems from poet

        await poets.push({
          ...poet,
          uid: snapshot.docs[i].id,
          totalPoems: totalPoems
        }); // Add poet to poems array
      }
      await this.setState({
        poets // Set poets in state when finished adding them to array
      });
      await this.props.firebase
        .users()
        .doc('collectionInfo')
        .get()
        .then(snapshot => {
          switch (loadingMore) {
            case true:
              if (this.state.filter == 'all') {
                this.setState({
                  totalPoets: snapshot.data().total, // Set total poet count to state
                  loadingMore: false
                });
                break;
              } else if (this.state.filter == 'classics') {
                this.setState({
                  totalPoets: snapshot.data().famous, // Set total poet count to state
                  loadingMore: false
                });
                break;
              } else if ((this.state.filter = 'original')) {
                this.setState({
                  totalPoets: snapshot.data().total - snapshot.data().famous, // Set total poet count to state
                  loadingMore: false
                });
                break;
              }
            case false:
              if (this.state.filter == 'all') {
                this.setState({
                  totalPoets: snapshot.data().total, // Set total poet count to state
                  loading: false
                });
                break;
              } else if (this.state.filter == 'classics') {
                this.setState({
                  totalPoets: snapshot.data().famous, // Set total poet count to state
                  loading: false
                });
                break;
              } else if ((this.state.filter = 'original')) {
                this.setState({
                  totalPoets: snapshot.data().total - snapshot.data().famous, // Set total poet count to state
                  loading: false
                });
                break;
              }
          }
        });
    } catch (err) {
      console.log('Error fetching splotching poems');
      switch (loadingMore) {
        case true:
          this.setState({ loadingMore: false });
        case false:
          this.setState({ poets: null, loading: false });
      }
    }
  }
  onListenForPoets = async loadingMore => {
    let poets;
    if (this.state.filter == 'all') {
      if (this.state.timeFrame == 'today') {
        poets = await this.props.firebase
          .users()
          .where('queryable', '==', true)
          .orderBy('dailyInk', 'desc')
          .limit(this.state.limit);
      } else if (this.state.timeFrame == 'week') {
        poets = await this.props.firebase
          .users()
          .where('queryable', '==', true)
          .orderBy('weeklyInk', 'desc')
          .limit(this.state.limit);
      } else if ((this.state.timeFrame = 'month')) {
        poets = await this.props.firebase
          .users()
          .where('queryable', '==', true)
          .orderBy('monthlyInk', 'desc')
          .limit(this.state.limit);
      }
    } else if (this.state.filter == 'classics') {
      if (this.state.timeFrame == 'today') {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', true)
          .orderBy('dailyInk', 'desc')
          .limit(this.state.limit);
      } else if (this.state.timeFrame == 'week') {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', true)
          .orderBy('weeklyInk', 'desc')
          .limit(this.state.limit);
      } else if ((this.state.timeFrame = 'month')) {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', true)
          .orderBy('monthlyInk', 'desc')
          .limit(this.state.limit);
      }
    } else if (this.state.filter == 'original') {
      if (this.state.timeFrame == 'today') {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', false)
          .orderBy('dailyInk', 'desc')
          .limit(this.state.limit);
      } else if (this.state.timeFrame == 'week') {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', false)
          .orderBy('weeklyInk', 'desc')
          .limit(this.state.limit);
      } else if ((this.state.timeFrame = 'month')) {
        poets = await this.props.firebase
          .users()
          .where('isFamous', '==', false)
          .orderBy('monthlyInk', 'desc')
          .limit(this.state.limit);
      }
    }
    loadingMore == false
      ? poets
          .get()
          .then(snapshot => {
            snapshot.size
              ? this.onGetPoets(snapshot, loadingMore)
              : this.setState({ poets: null, loading: false });
          })
          .catch(err => {
            console.log('Error fetching poets ' + err);
            this.setState({ poets: null, loading: false });
          })
      : poets
          .get()
          .then(snapshot => {
            snapshot.size
              ? this.onGetPoets(snapshot, loadingMore)
              : this.setState({ loadingMore: false });
          })
          .catch(err => {
            console.log('Error fetching poets ' + err);
            this.setState({ loadingMore: false });
          });
  };

  async switchTimeFrame() {
    try {
      let loadingMore = false;
      this.onListenForPoets(loadingMore);
    } catch (err) {
      console.log('Error switching time frame');
      this.setState({ poets: null, loading: false });
    }
  }

  loadMorePoets = () => {
    this.setState(
      state => ({ limit: state.limit + 5, loadingMore: true }),
      this.addPoets
    );
  };
  onSetTimeFrame(event, frame) {
    event.preventDefault();
    this.setState(
      { timeFrame: frame, limit: 10, loading: true }, // Time frame today, reset limit
      this.switchTimeFrame
    );
  }
  addPoets = () => {
    try {
      let loadingMore = true;
      this.onListenForPoets(loadingMore);
    } catch (err) {
      console.log('Error switching time frame');
      this.setState({ loadingMore: false });
    }
  };
  onSetFilter(event, filter) {
    event.preventDefault();
    this.setState(
      { filter: filter, limit: 10, loading: true }, // Time frame today, reset limit
      this.switchFilter
    );
  }
  async switchFilter() {
    try {
      console.log('Switching filter...');
      let loadingMore = false;
      this.onListenForPoets(loadingMore);
    } catch (err) {
      console.log('Error switching filter');
      this.setState({ poems: null, loading: false });
    }
  }
  render() {
    const {
      loading,
      poets,
      limit,
      loadingMore,
      totalPoets,
      timeFrame
    } = this.state;
    return (
      <div>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {loading == true ? (
                <Preloader />
              ) : (
                <div style={{ position: 'relative' }}>
                  <BrowseNavigation />
                  <Modal
                    filterPoetsModal={true}
                    filter={this.state.filter}
                    onSetFilter={(event, filter) =>
                      this.onSetFilter(event, filter)
                    }
                  />
                  <ul
                    id='toolbar'
                    style={{ marginTop: '50px', overflow: 'visible' }}
                    className='collection container'>
                    <div>
                      <span className='right' style={{ marginBottom: '10px' }}>
                        <a
                          style={{
                            background: '#da694b',
                            borderRadius: '18px'
                          }}
                          className='waves-effect z-depth-0 btn modal-trigger'
                          data-target='modal-filter'>
                          <i
                            style={{ color: 'white' }}
                            className='material-icons left'>
                            tune
                          </i>
                          filter
                        </a>
                      </span>
                    </div>
                  </ul>
                  <div
                    id='scroll'
                    style={{ top: '25px' }}
                    className='fixed-action-btn horizontal'>
                    <a
                      style={{ backgroundColor: '#da694b' }}
                      className='btn-floating btn-small'>
                      <i className='large material-icons'>arrow_drop_up</i>
                    </a>
                  </div>
                  <br />
                  <br />
                  <br />
                  <ul
                    style={{ marginTop: '-10px' }}
                    id='poems'
                    className='collection with-header container'>
                    <li
                      style={{ height: '125px' }}
                      id='ink-header'
                      className='collection-header'>
                      <p
                        style={{ marginTop: '13px', marginBottom: '7px' }}
                        id='my-poems'
                        className='futura ink-phrase'>
                        Ink Leaderboard
                      </p>
                      <div>
                        <a
                          onClick={event => this.onSetTimeFrame(event, 'today')}
                          style={{ color: '#413043' }}
                          className={[
                            timeFrame == 'today' && 'time-filter',
                            'time-filter-option'
                          ]
                            .filter(e => e)
                            .join(' ')}>
                          Today
                        </a>
                        &nbsp;&nbsp;
                        <a
                          onClick={event => this.onSetTimeFrame(event, 'week')}
                          style={{ color: '#413043' }}
                          className={[
                            timeFrame == 'week' && 'time-filter',
                            'time-filter-option'
                          ]
                            .filter(e => e)
                            .join(' ')}>
                          Week
                        </a>
                        &nbsp;&nbsp;
                        <a
                          onClick={event => this.onSetTimeFrame(event, 'month')}
                          style={{ color: '#413043' }}
                          className={[
                            timeFrame == 'month' && 'time-filter',
                            'time-filter-option'
                          ]
                            .filter(e => e)
                            .join(' ')}>
                          Month
                        </a>
                      </div>
                    </li>
                    <div>
                      {totalPoets < 1 ? (
                        <li className='collection-item' id='no-public'>
                          <center>
                            <svg
                              style={{ width: '200px', marginTop: '-5px' }}
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 2000 2000'>
                              <title>Poetryphile Leaderboard</title>
                              <g id='Layer_3' data-name='Layer 3'>
                                <path
                                  d='M1283.05,1070.76V929.24l-70.76-70.76V1566.1H787.71V858.48L717,929.24v283H363.14v424.57H1636.86v-566.1ZM717,1566.1H433.9v-283H717Zm849.15,0h-283V1141.52h283Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M1544.71,967.37v49.38H1365.05l56.77-59.44c20.13-21.15,35.23-39.63,46-56.77,6-9.44,12.11-20.44,12.11-34.91,0-2.36,0-14.07-6.69-21.46a22.21,22.21,0,0,0-16.51-6.77,24.09,24.09,0,0,0-19.42,9c-5.42,7.08-7.78,19.82-8.1,27.6h-60.07c.32-29.25,13.06-50.4,24.85-62.11,15.1-15.18,35.54-24.22,63.45-24.22,33.57,0,53.07,13.13,63.13,23.2,9,9,21.78,26.18,21.78,55,0,22.8-8.49,39.86-22.4,55.5l-.55.63L1481,967.37Z'
                                  style={{ fill: '#413043' }}
                                />
                                <path
                                  d='M520.6,1064.06c.33,8.39,3.69,17.12,9.06,22.5,5,5.37,13.1,8.73,22.83,8.73,11.42,0,18.48-5,22.84-9.74,5-5.71,6.71-11.75,6.71-18.47a25.59,25.59,0,0,0-8.73-19.81c-5.71-4.7-14.44-6.38-21.49-6.38a29.57,29.57,0,0,0-9.73,1.34v-39.29c11.74,1.68,19.13-1.68,23.84-5.71a21,21,0,0,0,7.38-15.78c0-6-2.68-12.76-6-16.12-3.69-3.69-10.07-6.05-16.45-6.05-7.06,0-12.76,2.36-16.13,5.72-5,4.69-7.72,12.76-7.72,19.8H472.91c1.34-23.5,11.75-41.3,21.83-51.37,16.45-17.13,37.6-21.83,59.09-21.83,16.8,0,39,3.36,55.75,19.81a60.91,60.91,0,0,1,18.13,43.32c0,9.74-1.68,18.47-6.71,27.54-4,7.39-9.07,13.09-17.13,17.13a54.73,54.73,0,0,1,26.53,20.48c6.38,9.4,9.4,20.15,9.4,32.91,0,23.84-10.41,39.62-19.81,49-12.09,12.09-33.58,23.85-66.82,23.85-21.49,0-44.67-5-64.14-23.18-11.42-10.4-25.19-29.54-27.87-58.42Z'
                                  style={{ fill: '#413043' }}
                                />
                                <polygon
                                  points='1283.05 716.95 1283.05 929.24 1212.29 858.48 1141.53 929.24 1070.76 858.48 1000 929.24 929.24 858.48 858.48 929.24 787.71 858.48 716.95 929.24 716.95 716.95 1283.05 716.95'
                                  style={{ fill: '#da694b' }}
                                />
                                <polygon
                                  points='1084.63 580.23 1084.63 654.32 915.37 654.32 915.37 580.23 961.2 580.23 961.2 403.47 915.37 403.47 915.37 332.56 1044.66 332.56 1044.66 580.23 1084.63 580.23'
                                  style={{ fill: '#413043' }}
                                />
                              </g>
                            </svg>
                            <h4 id='no-public-poems' className='futura'>
                              There are no poets!
                            </h4>
                          </center>
                        </li>
                      ) : (
                        <PoetList
                          poets={poets}
                          limit={limit}
                          loadMorePoets={this.loadMorePoets}
                          loadingMore={loadingMore}
                          totalPoets={totalPoets}
                          timeFrame={this.state.timeFrame}
                        />
                      )}
                    </div>
                  </ul>
                  <br />
                </div>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const PoetList = ({
  poets,
  limit,
  loadMorePoets,
  loadingMore,
  totalPoets,
  timeFrame
}) => (
  <ul>
    {poets &&
      poets.map(poet => (
        <li key={poet.uid} className='collection-item poet avatar'>
          <img
            alt='Poet avatar'
            id='poet-profile-img-ink'
            className='circle'
            src={poet.avatar}></img>
          <div style={{ fontSize: '17px' }}>
            <a
              style={{ display: 'block', marginTop: '4px' }}
              href={`/poets/@${poet.penName}`}
              className='poet-browse-name'>
              {poet.penName}
              <span style={{ fontWeight: 'bold' }}>
                <div className='ink-line ink-score'>
                  <span
                    style={{
                      color: '#da694b',
                      display: 'inline-block',
                      paddingRight: '3px',
                      paddingLeft: '5px'
                    }}>
                    (
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      color: '#413043',
                      fontSize: '17px'
                    }}>
                    {timeFrame == 'today'
                      ? poet.dailyInk
                      : timeFrame == 'month'
                      ? poet.monthlyInk
                      : poet.weeklyInk}
                    <svg
                      style={{
                        width: '50px',
                        marginLeft: '-15px',
                        position: 'absolute',
                        zIndex: 0
                      }}
                      id='Layer_1'
                      data-name='Layer 1'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 2000 2000'>
                      <title>ink drop</title>
                      <path d='M1285.91,1054.23a285.13,285.13,0,0,1-90.46,208.66c-109.23,102.48-281.67,102.48-390.9,0a285.89,285.89,0,0,1-6.71-410.83l184.5-184.49a25,25,0,0,1,35.32,0l184.5,184.49A285.07,285.07,0,0,1,1285.91,1054.23Z' />
                    </svg>
                  </span>
                  <span
                    style={{
                      color: '#da694b',
                      display: 'inline-block',
                      paddingLeft: '20px'
                    }}>
                    )
                  </span>
                </div>
              </span>
            </a>
          </div>
          <p style={{ fontSize: '17px', marginBottom: '5px' }}>
            {poet.totalPoems} poems
          </p>
          {poet.isFamous == true && <div className='chip'>Classic</div>}
        </li>
      ))}
    {limit >= totalPoets && loadingMore == false && <TheEnd />}
    {poets && totalPoets > limit && loadingMore == false && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='load-more' onClick={loadMorePoets}>
            Load More
          </a>
        </center>
      </li>
    )}
    {poets && totalPoets + 5 > limit && loadingMore == true && (
      <li className='collection-item'>
        <center>
          <a href='#!' className='loading-more'>
            Loading more...
          </a>
        </center>
      </li>
    )}
  </ul>
);

export default compose(withFirebase)(Ink);
