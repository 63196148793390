import React, { Component } from 'react';

class Privacy extends Component {
  componentDidMount() {
    document.title = 'Privacy Policy' + ' - Poetryphile';
  }
  render() {
    return (
      <div>
        <ul
          id='poems'
          style={{ marginTop: '50px' }}
          className='collection with-header container'>
          <li style={{ height: '90px' }} className='collection-header'>
            <p style={{ marginTop: '13px' }} id='my-poems' className='futura'>
              Privacy Policy
            </p>
          </li>
          <li style={{ fontSize: '17px' }} className='collection-item'>
            <p>
              <b>
                <span>1. How Poetryphile uses your information</span>
              </b>
            </p>
            <p>
              Help you efficiently access your information and user profile
              after you sign in
            </p>
            <p>Send you emails related to the Poetryphile Website and News</p>
            <p>Diagnose or fix technical problems.</p>
            <p>
              <b>
                <span>2. Sharing your information</span>
              </b>
            </p>
            <p>
              We will not rent or sell your information to third parties outside
              Poetryphile without your consent
            </p>
            <p>
              <b>
                <span>3. Cookies</span>
              </b>
            </p>
            <p>
              The cookies are short text files sent by a website to your
              browser, enabling the website to record information about your
              visit, such as selected language etc. Such information is then
              used to identify you when you revisit the website, as soon as the
              web server and the browser connect. The main purpose of cookies is
              to enable our web server to provide you with an environment which
              is based on your visits, and to make the website more personal and
              responsive to your individual needs, e.g. automatic pre-population
              of login credentials or other information.
            </p>
            <p>
              Our website uses one type of cookies: (i) session cookies that are
              stored in your browser cookie file until you leave the webpage
              (after the browser is closed, all such cookie files are erased)
            </p>
          </li>
        </ul>
      </div>
    );
  }
}

export default Privacy;
