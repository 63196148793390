export const LANDING = '/';
export const ABOUT = '/about';
export const MYPOEMS = '/mypoems';
export const EDITPOEM = '/poems/:id/edit';
export const SHOWPOEM = '/poems/:id';
export const NEW = '/new';
export const INK = '/ink';
export const SETTINGS = '/settings';
export const PROFILE = '/poets/@:penname';
export const BOOKMARKS = '/bookmarks';
export const NOTIFICATIONS = '/notifications';
export const TOS = '/tos';
export const PRIVACY = '/privacy';
export const FOLLOWING = '/following';
export const CLASSICS = '/classics';
export const CONTESTS = '/contests';
